import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

export const ChevronDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <defs>
        <clipPath id="jnie2vbwfa">
          <path d="M1440 0v1080H0V0h1440z" />
        </clipPath>
      </defs>
      <g clipPath="url(#jnie2vbwfa)" transform="translate(-280 -24)">
        <path
          d="m286 33 6 6 6-6"
          stroke="#213568"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
