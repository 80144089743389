import { ReservationCardGroupModel } from '../../../../../../ReservationModule/pages/CreateReservationPageNew/models';

export interface DepartureReportFormModel {
  from: string | null;
  to: string | null;
  rate_ids: Array<number>;
  room_type_ids: Array<number>;
  card_ids: ReservationCardGroupModel[];
  state?: DepartureReportStateEnum;
  balance: boolean;
  nationality_ids: number[] | null;
}

export enum DepartureReportStateEnum {
  DUE_OUT = 'due_out',
  CHECKED_OUT = 'checked_out',
}
