import { AxiosInstance } from 'axios';
import { GroupReservationService } from 'services/Reservations';

export interface GroupReservationServiceType {
  getAll: ReturnType<typeof GroupReservationService.getAll>;
  getById: ReturnType<typeof GroupReservationService.getById>;
  updateRooms: ReturnType<typeof GroupReservationService.updateRooms>;
  update: ReturnType<typeof GroupReservationService.update>;
  splitGroup: ReturnType<typeof GroupReservationService.splitGroup>;
  saveRooming: ReturnType<typeof GroupReservationService.saveRooming>;
}

export const initGroupReservationService = (axios: AxiosInstance): GroupReservationServiceType => ({
  getAll: GroupReservationService.getAll(axios),
  getById: GroupReservationService.getById(axios),
  updateRooms: GroupReservationService.updateRooms(axios),
  update: GroupReservationService.update(axios),
  splitGroup: GroupReservationService.splitGroup(axios),
  saveRooming: GroupReservationService.saveRooming(axios),
});
