import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { GuestModel } from "models/Reservations/Guest/GuestModel";
import { ResponseReservationListModel } from "models/Reservations/ReservationListModel";
import { BillingRouteEntityCardModel } from "models/Reservations/ReservationModel";
import { ReservationCardGroupModel } from "pages/ReservationModule/pages/CreateReservationPageNew/models";
import { ReservationPaymentTypesModel } from "pages/ReservationModule/pages/CreateReservationPageNew/models/ReservationPaymentTypesModel";
import { sprintf } from "sprintf-js";

export const discard =
  (axios: AxiosInstance) =>
  async (id: number): Promise<any> => {
    return await axios
      .delete(sprintf(ReservationsEndpoint.RESERVATION_DISCARD, id))
      .then(({ data }) => data);
  };

export interface FetchingRequestParamsModels {
  search?: string | null | undefined;
  from?: string;
  to?: string;
  date_type?:
    | "show_all"
    | "all"
    | "arrival"
    | "departure"
    | "in_house"
    | null
    | undefined;
  state_id?: string | number;
  card_id?: number | null;
  rate_id?: string | number;
  board_id?: string | number;
  room_type_id?: string | number;
  limit?: number;
  offset?: number;
}

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params: FetchingRequestParamsModels
  ): Promise<ResponseReservationListModel> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_ROOMS, { params })
      .then(({ data }) => data);
  };

export const getGuest =
  (axios: AxiosInstance) =>
  async (id: number): Promise<GuestModel> => {
    return await axios
      .get(sprintf(ReservationsEndpoint.RESERVATION_GET_GUEST, id))
      .then(({ data }) => data);
  };

export const updateRoomComment =
  (axios: AxiosInstance) =>
  async (params: { room: number; comment: string }): Promise<GuestModel> => {
    return await axios
      .patch(
        sprintf(ReservationsEndpoint.RESERVATION_UPDATE_COMMENT, params.room),
        { comment: params.comment }
      )
      .then(({ data }) => data);
  };

export const updateGuest =
  (axios: AxiosInstance) =>
  async (item: {
    id: number;
    data: { is_draft: boolean; guest_id: number };
  }): Promise<GuestModel> => {
    return await axios
      .patch(
        sprintf(ReservationsEndpoint.RESERVATION_UPDATE_GUEST, item.id),
        item.data
      )
      .then(({ data }) => data);
  };
export const searchCardGroup =
  (axios: AxiosInstance) =>
  async (params?: any): Promise<ReservationCardGroupModel[]> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_CARD_GROUP_SEARCH, { params })
      .then(({ data }) => data.items);
  };

export const searchToBillingRouteEntity =
  (axios: AxiosInstance) =>
  async (params?: {
    from?: string;
    to?: string;
    search: string;
  }): Promise<BillingRouteEntityCardModel[]> => {
    return await axios
      .get(sprintf(ReservationsEndpoint.RESERVATION_CARD_RESERVATION_SEARCH), {
        params,
      })
      .then(({ data }) => data);
  };

export const confirm =
  (axios: AxiosInstance) =>
  async (data: {
    id: number | string;
    payment_methods: ReservationPaymentTypesModel[];
  }): Promise<{ confirmation_number: string; voucher: string }> => {
    return await axios
      .post(sprintf(ReservationsEndpoint.RESERVATION_CONFIRM, data?.id), {
        payments: data?.payment_methods,
      })
      .then(({ data }) => data);
  };
export const checkRoomavAvilability =
  (axios: AxiosInstance) =>
  async (data: {
    room_number: string;
    arrival: string;
    departure: string;
    share?: boolean;
  }): Promise<{
    room_number: string;
    room_type_id: string;
    status: number;
  }> => {
    return await axios
      .post(ReservationsEndpoint.RESERVATION_CHECK_AVAILABILTY, { ...data })
      .then(({ data }) => data);
  };
