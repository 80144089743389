import { Box } from '@mui/material';
import { BackdropLoading } from 'components/BackdropLoading';
import { useRootContext } from 'components/RootContainer';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { CashRegisterReportRequestModel } from './api/models/CashRegisterReportRequestModel';
import { FilterFormProvider } from './components/FilterForm/FilterFormProvider';
import { getDefaultValues } from './components/FilterForm/defaultValues';
import { dataFormToRequest } from './utils/dataFormToRequest';

const CashRegisterReportPage: FC = () => {
  const {
    globalSettings,
    HotelApi: {
      report: {
        cashierReport: { cashRegisterReport },
      },
    },
  } = useRootContext();

  const exportPDF = useMutation({
    mutationKey: ['exportPDF'],
    mutationFn: (payload: CashRegisterReportRequestModel) => {
      return cashRegisterReport(payload);
    },
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <BackdropLoading isLoading={exportPDF.isLoading} />

      <FilterFormProvider
        defaultValues={getDefaultValues(globalSettings!)}
        onSubmit={data => {
          return exportPDF.mutate(dataFormToRequest(data));
        }}
      />
    </Box>
  );
};

export default CashRegisterReportPage;
