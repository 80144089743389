import { GlobalSettingsModel } from 'models/Shared/GlobalSettingsModel';
import { DepartureReportFormModel, DepartureReportStateEnum } from '../../models';

export const getDefaultValues = (
  globalSettings: GlobalSettingsModel
): DepartureReportFormModel => ({
  from: globalSettings?.frontend_date,
  to: null,
  card_ids: [],
  rate_ids: [],
  room_type_ids: [],
  balance: false,
  state: DepartureReportStateEnum.DUE_OUT,
  nationality_ids: null,
});
