import { AxiosInstance } from "axios";

import { CardGroupServiceModel, initCardGroupService } from "./GrouCard";
import { ReservationsGuestService, initReservationsGuestService } from "./GuestService";

export interface ChainReservationServices {
  guest: ReservationsGuestService;
  cardGroup: CardGroupServiceModel
}

export const initChainReservationServices = (
  axios: AxiosInstance
): ChainReservationServices => ({
  guest: initReservationsGuestService(axios),
  cardGroup: initCardGroupService(axios),
});
