import { AxiosInstance } from 'axios';
import * as FinancialAccountCreateService from './services';

export interface FinancialAccountCreateServiceType {
  create: ReturnType<typeof FinancialAccountCreateService.create>;
}

export const initFinancialAccountCreateService = (
  axios: AxiosInstance
): FinancialAccountCreateServiceType => ({
  create: FinancialAccountCreateService.create(axios),
});
