import { SettingsPaths } from "@constants/Navigation/AppPaths";
import { REPORT_RESERVATION_DAILY_FORECAST_PATH, REPORT_RESERVATION_FORECAST_BY_ROOM_TYPE_PATH, REPORT_RESERVATION_FOUR_WEEK_FORECAST_PATH, REPORT_RESERVATION_RESERVATION_LIST_PATH } from "@constants/Navigation/AppPaths/ReportPaths";
import { Box } from "@mui/material";
import { RootContextProps } from "components/RootContainer";
import i18next from "i18next";
import React, { FC } from "react";
import {
  RouteObject,
  useRouteError
} from "react-router-dom";
import { Breadcrumb } from "routing/components/Breadcrumb";
import DailyForecastReportPage from "../pages/DailyForecastReportPage";
import ForecastByRoomTypeReportPage from "../pages/ForecastByRoomTypeReportPage";
import FourWeekForecastReportPage from "../pages/FourWeekForecastReportPage";
import ReservationListReportPage from "../pages/ReservationListReportPage";




const LandingPage: FC = (): JSX.Element => {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/settings/hotel", { replace: true });
  // }, []);

  return <>LandingPage</>;
};

const ReportBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        height: "100%",
      }}
    >
      <h2>Error : </h2>
      <p>{error.message}</p>
    </Box>
  );
};

export const reportReservationRoutePaths = (api: RootContextProps): RouteObject[] => [

  {
    path: REPORT_RESERVATION_DAILY_FORECAST_PATH,
    element: (
      <React.Suspense fallback={<p>...LoadingPage</p>}>
        <DailyForecastReportPage />
      </React.Suspense>
    ),
    errorElement: <ReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("report.menu.daily_forecast" as any)}
          path={REPORT_RESERVATION_DAILY_FORECAST_PATH}
        />
      ),
    },
  },


  {
    path: REPORT_RESERVATION_FORECAST_BY_ROOM_TYPE_PATH,
    element: (
      <React.Suspense fallback={<p>...LoadingPage</p>}>
        <ForecastByRoomTypeReportPage />
      </React.Suspense>
    ),
    errorElement: <ReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("report.menu.forecast_by_room_type" as any)}
          path={SettingsPaths.SETTINGS_HOTEL_PATH}
        />
      ),
    },
  },

  {
    path: REPORT_RESERVATION_FOUR_WEEK_FORECAST_PATH,
    element: (
      <React.Suspense fallback={<p>...LoadingPage</p>}>
        <FourWeekForecastReportPage />
      </React.Suspense>
    ),
    errorElement: <ReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("report.menu.four_week_forecast" as any)}
          path={SettingsPaths.SETTINGS_HOTEL_PATH}
        />
      ),
    },
  },
  {
    path: REPORT_RESERVATION_RESERVATION_LIST_PATH,
    element: (
      <React.Suspense fallback={<p>...LoadingPage</p>}>
        <ReservationListReportPage />
      </React.Suspense>
    ),
    errorElement: <ReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t("report.menu.reservation_list" as any)}
          path={SettingsPaths.SETTINGS_HOTEL_PATH}
        />
      ),
    },
  },











];
