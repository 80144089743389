import { LIMIT_ITEMS_SELECT } from '@constants/Pagination';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { RootContext } from 'components/RootContainer';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { CurrencyModel } from 'models/Settings/Financial/CurrencyModel';
import { FC, ReactNode, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { ReservationListReportFormModel } from '../../../models';

export const CurrencyField: FC<{
  value: CurrencyModel | null;
  onChange: (value: CurrencyModel | null) => void;
  error?: ReactNode;
}> = ({ value, onChange, error }) => {
  const {
    HotelApi: {
      settings: {
        financial: {
          currency: { getAll },
        },
      },
    },
  } = useContext(RootContext);

  const { watch } = useFormContext<ReservationListReportFormModel>();
  const withRevenueValue = watch('with_revenue');
  const disabled = withRevenueValue === false;

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['currency'],
    queryFn: () => {
      return getAll({
        offset: 0,
        limit: LIMIT_ITEMS_SELECT,
      });
    },
    enabled: !disabled,
  });

  if (isLoading) {
    return <>...</>;
  }

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="reservation-filter-room-types">Currency</InputLabel>
      <Select
        error={Boolean(error)}
        disabled={disabled}
        labelId="reservation-filter-room-types"
        value={value ? value.id : null}
        onChange={e => {
          const selectedCurrency = (data ?? [])?.find(item => item.id === Number(e.target.value));
          onChange(selectedCurrency ?? null);
        }}
      >
        <MenuItem firstItem value="all"></MenuItem>
        {(data || [])?.map((item, key) => (
          <MenuItem key={`room-type-key-${key}`} value={item.id!}>
            {`${item.name}-${item.code} ${item.is_local ? '(local money)' : ''}`}
          </MenuItem>
        ))}
      </Select>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
