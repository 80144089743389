import { AxiosInstance } from 'axios';
import {
  AccessibilityServiceModel,
  initAccessibilityService,
} from './Accessibility/AccessibilityService';
import { LanguageServiceModel, initLanguageService } from './Language/LanguageService';
import { SecurityServiceModel, initSecurityService } from './Security/SecurityService';

export interface SettingApplicationSettingsServiceModel {
  Language: LanguageServiceModel;
  Security: SecurityServiceModel;
  Accessibility: AccessibilityServiceModel;
}

export const initSettingApplicationSettingsService = (
  axios: AxiosInstance
): SettingApplicationSettingsServiceModel => ({
  Language: initLanguageService(axios),
  Security: initSecurityService(axios),
  Accessibility: initAccessibilityService(axios),
});
