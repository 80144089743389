import { Autocomplete, TextField } from '@mui/material';
import { RootContext } from 'components/RootContainer';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { ReservationCardGroupModel } from 'pages/ReservationModule/pages/CreateReservationPageNew/models';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';

export const CompanyField: FC<{
  value: ReservationCardGroupModel | null;
  onChange: (value: ReservationCardGroupModel | null) => void;
}> = ({ value, onChange }) => {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('reservation');

  const {
    HotelApi: {
      reservations: {
        reservation: { common: CommonService },
      },
    },
  } = useContext(RootContext);

  const [cardSearch, setCardSearch] = useState<string>('');

  const [searchDebounced] = useDebounce(cardSearch, 500);

  const { data: cards, isLoading: isCardsLoading } = useQuery({
    queryKey: ['search-company'],
    queryFn: () =>
      CommonService.searchCardGroup({
        search: searchDebounced,
        limit: 500,
        offset: 0,
      }),
    enabled: true,
  });

  return (
    <Autocomplete
      options={cards ?? []}
      loading={isCardsLoading}
      getOptionLabel={option => option.name ?? ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, state) => (
        <MenuItem {...props} value={option.id} key={`${option?.id}-${option?.name}`}>
          {option.name}
        </MenuItem>
      )}
      value={value !== undefined ? value : null}
      onChange={async (e, value) => {
        onChange(value);
      }}
      inputValue={cardSearch}
      onInputChange={(e, value, reason) => {
        if (reason === 'clear') {
          onChange(null);
          setCardSearch('');
        } else {
          setCardSearch(value);
        }
      }}
      renderInput={props => (
        <TextField
          {...props}
          className="background"
          variant="standard"
          fullWidth
          label={t('generalInformations.company')}
          placeholder={t('generalInformations.companyPlaceholder')}
          inputProps={{
            ...props.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};
