import { reportMenuItems } from '@constants/Navigation/reportRoutes';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { NavLinkBehavior } from 'components/NavLinkBehavior';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { slugify } from 'utils/slugify';
import { ChevronDownIcon } from '../../../components/SvgIcons/ChevronDownIcon';
import { ReportMenuModel } from './ReportMenuItem';
import { Container } from './styled/Container';
import { ReportButton } from './styled/ReportButton';

export const ReportMenu: FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container direction={'column'} spacing={'0.5rem'}>
      <MenuTree menuItems={reportMenuItems} />
    </Container>
  );
};

const MenuTree: React.FC<{ menuItems: Array<ReportMenuModel> }> = ({ menuItems }) => {
  const { t } = useTranslation('common');
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

  const toggleExpand = (path: string) => {
    setExpandedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(path)) {
        newSet.delete(path);
      } else {
        newSet.add(path);
      }
      return newSet;
    });
  };

  const renderMenu = (items: Array<ReportMenuModel>): JSX.Element => {
    return (
      <>
        {items.map(item => (
          <Box key={item.path} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {(item?.children || [])?.length > 0 ? (
              <>
                <Box
                  key={item?.path}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: 8,
                    px: 1.5,
                    py: 1,
                  }}
                  onClick={() => toggleExpand(item.path)}
                >
                  <Typography
                    sx={{
                      ml: 1,
                      color: theme => theme.palette.colors.gray.o90,
                      fontWeight: expandedItems.has(item.path) ? 600 : 500,
                    }}
                  >
                    {t(`${item.name}` as any)}
                  </Typography>
                  <IconButton size="small" sx={{ mr: 1 }}>
                    {expandedItems.has(item.path) ? (
                      <ChevronDownIcon sx={{ rotate: '180deg' }} />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={expandedItems.has(item.path)} timeout="auto">
                  <Box sx={{ pl: 2 }}>{item.children && renderMenu(item.children)}</Box>
                </Collapse>
              </>
            ) : (
              <ReportButton
                id={`${slugify(item.name)}-settings-link-menu`}
                LinkComponent={NavLinkBehavior}
                sx={{
                  justifyContent: 'flex-start',
                  fontWeight: 400,
                  my: 0.5,
                }}
                href={item.path}
                fullWidth
                startIcon={item.icon}
                variant="outlined"
              >
                <span
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '200px',
                  }}
                >
                  {t(`${item.name}` as any)}
                </span>
              </ReportButton>
            )}
          </Box>
        ))}
      </>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {renderMenu(menuItems)}
    </Box>
  );
};
