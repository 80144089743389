import { RESERVATION_FINANCIAL_ACCOUNT_PATH } from '@constants/ApiEndpoints/GroupReservationEndpoint';
import { AxiosInstance } from 'axios';
import { FinancialAccountCreateDataModel } from '../models/FinancialAccountCreateDataModel';

export const create =
  (axios: AxiosInstance) =>
  async (
    data: FinancialAccountCreateDataModel
  ): Promise<{
    data: {
      guest_card_id: number;
      id: number;
    };
  }> => {
    const response = await axios.post(RESERVATION_FINANCIAL_ACCOUNT_PATH, data);
    return response;
  };
