import { format } from 'date-fns';
import { GlobalSettingsModel } from 'models/Shared/GlobalSettingsModel';
import { DATE_PICKER_FORMAT_MONTH } from '../../../../../../../../@constants/date';
import { FilterEnum, ReservationListReportFormModel, SubFilterEnum } from '../../models';

export const getDefaultValues = (
  globalSettings: GlobalSettingsModel
): ReservationListReportFormModel => ({
  month: format(new Date(globalSettings?.frontend_date), DATE_PICKER_FORMAT_MONTH),
  company: null,
  rates: [],
  roomType: null,
  with_revenue: false,
  currency: null,
  currency_application: null,
  fixed_at: '',
  state_id: null,
  sub_filter: SubFilterEnum.NONE,
  filter: FilterEnum.ROOM,
  gross_revenue: false,
});
