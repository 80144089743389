import { REPORT_PATH } from './RootPaths';

export const REPORT_RESERVATION_PATH = `${REPORT_PATH}/reservation`;
export const REPORT_RESERVATION_DAILY_FORECAST_PATH = `${REPORT_RESERVATION_PATH}/daily-forecast`;
export const REPORT_RESERVATION_FORECAST_BY_ROOM_TYPE_PATH = `${REPORT_RESERVATION_PATH}/forecast-by-room-type`;
export const REPORT_RESERVATION_FOUR_WEEK_FORECAST_PATH = `${REPORT_RESERVATION_PATH}/four-week-forecast`;
export const REPORT_RESERVATION_RESERVATION_LIST_PATH = `${REPORT_RESERVATION_PATH}/reservation-list`;

/**
 * Report Frontdesk
 */
export const REPORT_FRONTDESK_PATH = `${REPORT_PATH}/frontdesk`;
export const REPORT_FRONTDESK_ARRIVAL_PATH = `${REPORT_FRONTDESK_PATH}/arrival`;
export const REPORT_FRONTDESK_DEPARTURE_PATH = `${REPORT_FRONTDESK_PATH}/departure`;
export const REPORT_FRONTDESK_INHOUSE_PATH = `${REPORT_FRONTDESK_PATH}/inhouse`;
export const REPORT_FRONTDESK_DAILY_REPORT_PATH = `${REPORT_FRONTDESK_PATH}/daily-report`;
export const REPORT_FRONTDESK_LOGS_REPORT_PATH = `${REPORT_FRONTDESK_PATH}/logs`;

/**
 * Report Cashier
 */
export const REPORT_CASHIER_PATH = `${REPORT_PATH}/cashier`;
export const REPORT_CASHIER_CASH_REGISTER_PATH = `${REPORT_CASHIER_PATH}/cash-register`;

/**
 * Report Housekeeping
 */
export const REPORT_HOUSEKEEPING_PATH = `${REPORT_PATH}/housekeeping`;

/**
 * Report Financial
 */
export const REPORT_FINANCIAL_PATH = `${REPORT_PATH}/financial`;

/**
 * Report Night Audit
 */
export const REPORT_NIGHT_AUDIT_PATH = `${REPORT_PATH}/night-audit`;
