import { ReservationListReportFormModel, ReservationListReportRequestModel } from '../models';

export const dataFormToRequest = (
  data: ReservationListReportFormModel
): ReservationListReportRequestModel => {
  return {
    month: data.month,
    currency_id: data.currency ? Number(data.currency.id) : null,
    fixed_at: data?.fixed_at ? Number(data?.fixed_at) : null,
    rates: (data?.rates || []).map(item => Number(item)),
    room_type_id: data?.roomType,
    state_id: data?.state_id,
    sub_filter: data?.sub_filter,
    filter: data?.filter,
    gross_revenue: data?.gross_revenue,
    card_id: data?.company?.id,
  };
};
