import { DATE_PICKER_FORMAT } from '@constants/date';
import { Box, ClickAwayListener, Divider, IconButton, Stack, TextFieldProps } from '@mui/material';
import { CalendarPickerProps as MuiCalendarPickerProps } from '@mui/x-date-pickers/CalendarPicker';
import { format, isValid, parse } from 'date-fns';
import React, { FC, RefObject, useCallback, useId, useMemo, useRef, useState } from 'react';
import CalendarPicker, { CalendarPickerProps } from './components/CalendarPicker';
import { CalenderPopper, LeftButtonIcon, RightButtonIcon } from './components/Shared';
import useCalendarActions from './hooks/useCalendarActions';
import useDefaultMonthCalendar from './hooks/useDefaultMonthCalendar';

type ButtoneRef = RefObject<HTMLButtonElement> | null | undefined;
type RenderInputProps = {
  startProps: TextFieldProps;
  endProps: TextFieldProps;
};

type DateRangePickerProps = Omit<MuiCalendarPickerProps<Date>, 'date' | 'onChange'> & {
  value: Array<Date | null>;
  onChange: (value: any) => void;
  renderInput: (props: RenderInputProps) => React.ReactElement;
  disabledDays?: Array<Date>;
  multipleCalendars?: boolean;
};

const DateRangePicker: FC<DateRangePickerProps> = ({
  value = [null, null],
  onChange,
  renderInput,
  disabledDays,
  multipleCalendars,
  ...rest
}) => {
  const [start, end] = value;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setFocus(null);
  };

  const [focus, setFocus] = useState<'start' | 'end' | null>(null);
  const [preSelectedDate, setPreSelectedDate] = useState<Date | null>(new Date());

  const { firstDefaultCalendarMonth, secondDefaultCalendarMonth } = useDefaultMonthCalendar(start);

  const handleChangeDateHover = (value: Date | null) => setPreSelectedDate(value);

  const renderDateFormat = (_date: Date | null) => (_date ? format(_date, DATE_PICKER_FORMAT) : '');

  const { isFirstDay, isLastDay, isInInterval, isPreSelected } = useCalendarActions({
    start,
    end,
    preSelectedDate,
    focus,
  });

  const firstLeftButtonRef = useRef(null) as ButtoneRef;
  const firstRightButtonRef = useRef(null) as ButtoneRef;
  const secondLeftButtonRef = useRef(null) as ButtoneRef;
  const secondRightButtonRef = useRef(null) as ButtoneRef;

  const onStartFocus = () => {
    setFocus('start');
  };

  const onEndFocus = () => {
    setFocus('end');
  };

  const open = Boolean(anchorEl);

  const handleChange = useCallback(
    (value: Date | null) => {
      if (!value) return;

      const formattedDate = format(value, DATE_PICKER_FORMAT);

      if (focus !== 'end') {
        // Handling start date selection
        setInputValues(prev => ({
          start: formattedDate,
          end: prev.end,
        }));
        onChange?.([value, end]);
        endInputRef.current?.focus();
        setFocus('end');
        if (end && end < value) {
          setInputValues(prev => ({
            start: formattedDate,
            end: '',
          }));
          onChange?.([value, null]);
        }
      } else {
        // Handling end date selection
        if (start) {
          const startDate = new Date(start.getTime());
          const endDate = new Date(value.getTime());
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);

          if (endDate < startDate) {
            setInputValues({
              start: formattedDate,
              end: '',
            });
            onChange?.([value, null]);
            return;
          }

          setInputValues(prev => ({
            start: prev.start,
            end: formattedDate,
          }));
          onChange?.([start, value]);
          handleClose();
        } else {
          setInputValues({
            start: '',
            end: '',
          });
          startInputRef.current?.focus();
          setFocus('start');
        }
      }
    },
    [focus, start, end, onChange]
  );

  const calendarPickerProps = useMemo(
    (): CalendarPickerProps => ({
      view: 'day',
      onChange: handleChange,
      handleChangeDateHover,
      isInInterval,
      isPreSelected,
      isFirstDay,
      isLastDay,
      ...rest,
      date: null,
    }),
    [handleChange, handleChangeDateHover, isInInterval, isPreSelected, isFirstDay, isLastDay, rest]
  );

  const popoverId = useId();

  const [inputValues, setInputValues] = useState<{
    start: string;
    end: string;
  }>({
    start: renderDateFormat(start),
    end: renderDateFormat(end),
  });

  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  const renderInputProps = (date: Date | null, isStart: boolean) => ({
    inputRef: isStart ? startInputRef : endInputRef,
    value: isStart ? inputValues.start : inputValues.end,
    autoComplete: 'new-password',
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, isStart),
  });

  const startProps = {
    ...renderInputProps(start, true),
    onFocus: onStartFocus,
    focused: open && focus === 'start',
  };

  const endProps = {
    ...renderInputProps(end, false),
    onFocus: onEndFocus,
    focused: open && focus === 'end',
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, isStart: boolean) => {
    let value = e.target.value;

    if (value.length === 2 || value.length === 5) {
      if (!value.endsWith('/')) {
        value = value + '/';
      }
    }

    setInputValues(prev => ({
      ...prev,
      [isStart ? 'start' : 'end']: value,
    }));

    const parsedDate = parse(value, DATE_PICKER_FORMAT, new Date());
    if (isValid(parsedDate)) {
      if (isStart) {
        onChange?.([parsedDate, end]);
      } else {
        onChange?.([start, parsedDate]);
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent={'onMouseDown'}>
      <Box>
        <Box
          onClick={handleOpen}
          sx={{
            height: 'auto',
          }}
          aria-owns={open ? popoverId : undefined}
          aria-haspopup={true}
        >
          {renderInput({ startProps, endProps })}
        </Box>

        <CalenderPopper id={popoverId} open={open} anchorEl={anchorEl}>
          <Box
            sx={{
              bgcolor: 'common.white',
              boxShadow: ' 0 8px 16px -4px rgba(0, 0, 0, 0.1)',
            }}
          >
            {!multipleCalendars ? (
              <CalendarPicker
                {...calendarPickerProps}
                defaultCalendarMonth={firstDefaultCalendarMonth}
                componentsProps={{
                  switchViewButton: {
                    sx: { display: 'none' },
                  },
                }}
                components={{
                  LeftArrowButton: _props => (
                    <IconButton {..._props}>
                      <LeftButtonIcon />
                    </IconButton>
                  ),
                  RightArrowButton: _props => (
                    <IconButton {..._props}>
                      <RightButtonIcon />
                    </IconButton>
                  ),
                }}
              />
            ) : (
              <Stack
                direction="row"
                justifyContent="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <CalendarPicker
                  {...calendarPickerProps}
                  defaultCalendarMonth={firstDefaultCalendarMonth}
                  componentsProps={{
                    rightArrowButton: {
                      sx: { display: 'none' },
                    },
                    switchViewButton: {
                      sx: { display: 'none' },
                    },
                  }}
                  components={{
                    LeftArrowButton: _props => (
                      <IconButton
                        {..._props}
                        ref={firstLeftButtonRef}
                        onClick={() => {
                          _props?.onClick?.();
                          secondLeftButtonRef?.current?.click?.();
                        }}
                      >
                        <LeftButtonIcon />
                      </IconButton>
                    ),
                    RightArrowButton: _props => (
                      <IconButton {..._props} ref={firstRightButtonRef} />
                    ),
                  }}
                />
                <CalendarPicker
                  {...calendarPickerProps}
                  defaultCalendarMonth={secondDefaultCalendarMonth}
                  componentsProps={{
                    leftArrowButton: {
                      sx: { display: 'none' },
                    },
                    switchViewButton: {
                      sx: { display: 'none' },
                    },
                  }}
                  components={{
                    LeftArrowButton: _props => <IconButton {..._props} ref={secondLeftButtonRef} />,
                    RightArrowButton: _props => (
                      <IconButton
                        {..._props}
                        ref={secondRightButtonRef}
                        onClick={() => {
                          _props?.onClick?.();
                          firstRightButtonRef?.current?.click();
                        }}
                      >
                        <RightButtonIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Stack>
            )}
          </Box>
        </CalenderPopper>
      </Box>
    </ClickAwayListener>
  );
};

export default DateRangePicker;
