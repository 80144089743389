import { Box } from '@mui/material';
import { BackdropLoading } from 'components/BackdropLoading';
import { useRootContext } from 'components/RootContainer';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { FilterFormProvider } from './components/FilterForm/FilterFormProvider';
import { getDefaultValues } from './components/FilterForm/defaultValues';
import { ReservationListReportRequestModel } from './models';
import { dataFormToRequest } from './utils/dataFormToRequest';

const ForecastByRoomTypeReportPage: FC = () => {
  const {
    globalSettings,
    HotelApi: {
      report: {
        reservationReport: { forecastByRoomType },
      },
    },
  } = useRootContext();

  const exportPDF = useMutation({
    mutationKey: ['exportPDF'],
    mutationFn: (payload: ReservationListReportRequestModel) => {
      return forecastByRoomType(payload);
    },
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <BackdropLoading isLoading={exportPDF.isLoading} />

      <FilterFormProvider
        defaultValues={getDefaultValues(globalSettings!)}
        onSubmit={data => exportPDF.mutate(dataFormToRequest(data))}
      />
    </Box>
  );
};

export default ForecastByRoomTypeReportPage;
