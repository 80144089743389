import { SettingsEndpoint } from '@constants/ApiEndpoints';
import { AxiosInstance } from 'axios';
import { SegmentMarketCodeModel } from 'models/Settings/Reservation/SegmentsAndMarketCodes/SegmentMarketCodeModel';
import { FetchingRequestParamsModels } from 'models/Shared/FetchingRequestParamsModels';
import { MarketCodeRevenuManagementModel } from '../../../models/Settings/Reservation/SegmentsAndMarketCodes/MarketCodeRevenuManagementModel';

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<SegmentMarketCodeModel> => {
    return await axios.delete(
      `${SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_SEGMENT}/${id}`
    );
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<SegmentMarketCodeModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_SEGMENT, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: SegmentMarketCodeModel): Promise<SegmentMarketCodeModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_SEGMENT, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: SegmentMarketCodeModel): Promise<SegmentMarketCodeModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_SEGMENT}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<SegmentMarketCodeModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_RESERVATION_SEGMENT_MARKET_SEGMENT}/${id}`)
      .then(({ data }) => data);
  };

export const getMarketCodesService =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels | undefined
  ): Promise<MarketCodeRevenuManagementModel[]> =>
    await axios
      .get(SettingsEndpoint.SETTINGS_RESERVATION_MARKET_CODE, { params })
      .then(({ data }) => data?.items);
