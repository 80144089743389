import { AxiosInstance } from 'axios';
import {
  CashierReportServiceType,
  initCashierReportService,
} from '../pages/ReportCashierModule/api';
import {
  FrontdeskReportServiceType,
  initFrontdeskReportService,
} from '../pages/ReportFrontdeskModule/api';
import {
  initReservationReportService,
  ReservationReportServiceType,
} from '../pages/ReportRservationModule/api';

export interface ReportServiceType {
  reservationReport: ReservationReportServiceType;
  frontDeskReport: FrontdeskReportServiceType;
  cashierReport: CashierReportServiceType;
}

export const initReportService = (axios: AxiosInstance): ReportServiceType => ({
  reservationReport: initReservationReportService(axios),
  frontDeskReport: initFrontdeskReportService(axios),
  cashierReport: initCashierReportService(axios),
});
