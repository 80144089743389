import { GlobalSettingsModel } from 'models/Shared/GlobalSettingsModel';
import { InHouseReportFormModel } from '../../models';

export const getDefaultValues = (globalSettings: GlobalSettingsModel): InHouseReportFormModel => ({
  from: globalSettings?.frontend_date,
  to: null,
  card_ids: [],
  rate_ids: [],
  room_type_ids: [],
  day_price: false,
  all_pax: false,
  nationality_ids: null,
});
