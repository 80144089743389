import { GlobalSettingsModel } from 'models/Shared/GlobalSettingsModel';
import { CashRegisterReportFormModel } from '../../models';

export const getDefaultValues = (
  globalSettings: GlobalSettingsModel
): CashRegisterReportFormModel => ({
  from: globalSettings?.frontend_date,
  to: null,
  cash_register_id: null,
  cashier_id: null,
});
