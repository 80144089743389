import { ChargeCodeTypesModel } from "./system";

export const ACTIVE_ITEMS = {
  column: "is_active",
  operator: "is",
  value: "true",
};

export const IS_NOT_COMERCIAL = {
  column: "roomDetails.is_comercial",
  operator: "like",
  value: false,
};

export const TOURISt_TAX = {
  column: "charge_type",
  operator: "like",
  value: ChargeCodeTypesModel.TOURISTIC_TAX,
};
