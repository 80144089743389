import { AxiosInstance } from 'axios';
import {
  DailyForecastReportServiceType,
  initDailyForecastReportService,
} from '../pages/DailyForecastReportPage/api';
import {
  ForecastByRoomTypeReportServiceType,
  initForecastByRoomTypeReportService,
} from '../pages/ForecastByRoomTypeReportPage/api';
import {
  FourWeekForecastReportServiceType,
  initFourWeekForecastReportService,
} from '../pages/FourWeekForecastReportPage/api';
import {
  initReservationListReportService,
  ReservationListReportServiceType,
} from '../pages/ReservationListReportPage/api';

export interface ReservationReportServiceType {
  reservationListReport: ReservationListReportServiceType;
  dailyForecastReport: DailyForecastReportServiceType;
  forecastByRoomType: ForecastByRoomTypeReportServiceType;
  fourWeekForecastReport: FourWeekForecastReportServiceType;
}

export const initReservationReportService = (
  axios: AxiosInstance
): ReservationReportServiceType => ({
  reservationListReport: initReservationListReportService(axios),
  dailyForecastReport: initDailyForecastReportService(axios),
  forecastByRoomType: initForecastByRoomTypeReportService(axios),
  fourWeekForecastReport: initFourWeekForecastReportService(axios),
});
