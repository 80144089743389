import { Paths } from '@constants/Navigation/AppPaths';
import LoadingPage from 'components/LoadingPage';
import { RootContextProps } from 'components/RootContainer';
import React, { lazy } from 'react';
import { Outlet, RouteObject, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'routing/components/Breadcrumb';

const ArAccountInterPage = lazy(() => import('../ArAccountPage'));
const ArAccountTransactionsPage = lazy(() => import('../AccountConfirmationsPage'));

const ArAccountBreadcrumb = () => {
  const location = useLocation();
  return (
    <Breadcrumb label={(location.state as any)?.arAccountName || 'Account Details'} path={null} />
  );
};

const ArAccountPageBreadcrumb = () => (
  <Breadcrumb label={'Ar Account'} path={Paths.AR_ACCOUNT_PAGE_PATH} />
);

export const FinancialRoutes = (api: RootContextProps): RouteObject[] => [
  {
    path: Paths.AR_ACCOUNT_PAGE_PATH,
    element: <Outlet />,
    handle: {
      crumb: () => <ArAccountPageBreadcrumb />,
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<LoadingPage />}>
            <ArAccountInterPage />
          </React.Suspense>
        ),
      },

      {
        path: ':id/confirmations',
        element: (
          <React.Suspense fallback={<LoadingPage />}>
            <ArAccountTransactionsPage />
          </React.Suspense>
        ),
        handle: {
          crumb: () => <ArAccountBreadcrumb />,
        },
      },
    ],
  },
];

export default FinancialRoutes;
