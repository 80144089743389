import { RootContextProps } from 'components/RootContainer';
import { Crumb } from 'components/TranslatableCrub';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { Breadcrumb } from 'routing/components/Breadcrumb';
import { GroupReservationResponse } from 'services/Reservations/GroupReservation';
import financialAccountRoutes from '../pages/FinancialAccountModule/routes';
import { ReservationGroupCreateModel } from '../pages/GroupReservationPage/models';

const StayCardPage = lazy(() => import('../pages/StayCardPage'));

const CreateReservationPageNew = lazy(() => import('../pages/CreateReservationPageNew'));

const ReservationListPage = lazy(() => import('../pages/ReservationListPage'));

const ForecastPage = lazy(() => import('../pages/ForecastPage'));

const GroupReservationList = lazy(
  () => import('../../ReservationModule/pages/GroupReservationPage/List')
);
const GroupReservationCreate = lazy(() => import('../pages/GroupReservationPage/Forms/Create'));

const EditGroupReservationPage = lazy(() => import('../pages/GroupReservationPage/Forms/Edit'));

const reservationRoutes = (api: RootContextProps): RouteObject[] => [
  {
    index: true,
    element: (
      <Suspense fallback={<>...</>}>
        <ReservationListPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="reservation.breadcrubms.find-reservation" path={null} />,
    },
  },
  {
    path: 'create-reservation',
    element: (
      <Suspense fallback={<>...</>}>
        <CreateReservationPageNew key="create-reservation" />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="reservation.breadcrubms.create-reservation" path={null} />,
    },
  },
  {
    path: ':id/edit-reservation',
    element: (
      <Suspense fallback={<>...</>}>
        <CreateReservationPageNew key="edit-reservation" />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="reservation.breadcrubms.find-reservation" path={null} />,
    },
  },

  {
    path: 'stay-card/:guestId/:voucher/:confirmationNumber',
    element: (
      <Suspense fallback={<>...</>}>
        <StayCardPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="reservation.breadcrubms.stay-card" path={null} />,
    },
  },

  {
    path: 'forecast',
    element: (
      <Suspense fallback={<>...</>}>
        <ForecastPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="reservation.breadcrubms.forcast-available-room" path={null} />,
    },
  },

  {
    path: 'financial-account',
    // handle: {
    //   crumb: () => <Breadcrumb label="" path={null} />,
    // },
    children: financialAccountRoutes(api),
  },
  // {
  //   path: 'financial-account',
  //   element: (
  //     <Suspense fallback={<>...</>}>
  //       <FinancialAccountPage />
  //     </Suspense>
  //   ),
  //   handle: {
  //     crumb: () => <Crumb label="frontDesk:financialAccount" />,
  //   },
  // },
  // {
  //   path: FINANCIAL_ACCOUNT_FORM_PATH,
  //   element: (
  //     <Suspense fallback={<>...</>}>
  //       <FinancialAccountForm />
  //     </Suspense>
  //   ),
  //   handle: {
  //     crumb: () => <Crumb label="frontDesk:financialAccount" />,
  //   },
  // },

  // {
  //   path: 'financial-account/:guestId/:accountNumber',
  //   element: (
  //     <Suspense fallback={<>...</>}>
  //       <FinancialAccountChargeGroup />
  //     </Suspense>
  //   ),
  //   handle: {
  //     crumb: () => <Crumb label="Financial Account . Charges And Payments" path={null} />,
  //   },
  // },

  {
    path: 'group-reservation',
    handle: {
      crumb: () => <Breadcrumb label="Group Reservation" path={null} />,
    },
    children: [
      {
        index: true,
        handle: {
          crumb: () => <Breadcrumb label="List" path={null} />,
        },
        element: (
          <Suspense fallback={<>...</>}>
            <GroupReservationList />
          </Suspense>
        ),
      },
      {
        path: 'create',
        handle: {
          crumb: () => <Breadcrumb label="Create" path={null} />,
        },
        element: (
          <Suspense fallback={<>...</>}>
            <GroupReservationCreate />
          </Suspense>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <Suspense fallback={<>...</>}>
            <EditGroupReservationPage />
          </Suspense>
        ),
        loader: async ({ params }): Promise<GroupReservationResponse | null> => {
          const {
            HotelApi: {
              reservations: {
                groupReservation: { getById },
              },
            },
          } = api;
          if (params?.id) {
            return await getById(Number(params.id));
          }
          throw new Response('Not Found', { status: 404 });
        },
        handle: {
          crumb: (data: ReservationGroupCreateModel) => (
            <Breadcrumb label={data?.group_card_id?.toString()!} path={null} />
          ),
        },
      },
    ],
  },
];

export default reservationRoutes;
