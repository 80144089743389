import { DepartureReportRequestModel } from '../api/models/DepartureReportRequestModel';
import { DepartureReportFormModel } from '../models';

export const dataFormToRequest = (data: DepartureReportFormModel): DepartureReportRequestModel => ({
  from: data.from,
  to: data.to,
  card_ids: data.card_ids.map(card => card.id),
  rate_ids: data.rate_ids,
  room_type_ids: data.room_type_ids,
  state: data.state,
  balance: data.balance,
  nationality_ids: data.nationality_ids,
});
