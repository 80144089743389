import * as yup from 'yup';

export const schema = yup.object().shape({
  from: yup.string().nullable().required("Le champ 'from' est obligatoire."),
  to: yup.string().nullable().required("Le champ 'to' est obligatoire."),
  // card_ids: yup.array().of(yup.object()).nullable(),
  // rate_ids: yup.array().of(yup.number()).nullable(),
  // room_type_ids: yup.array().of(yup.number()).nullable(),
  // state_id: yup.number().nullable(),
  // price: yup.boolean().required(),
  // nationality_id: yup.number().nullable(),
});
