import { AxiosInstance } from 'axios';
import { SegmentMarketCodeService } from 'services';

export interface SegmentMarketCodeServiceModel {
  getAll: ReturnType<typeof SegmentMarketCodeService.getAll>;
  create: ReturnType<typeof SegmentMarketCodeService.create>;
  update: ReturnType<typeof SegmentMarketCodeService.update>;
  remove: ReturnType<typeof SegmentMarketCodeService.remove>;
  toggleStatus: ReturnType<typeof SegmentMarketCodeService.toggleStatus>;
  getMarketCodesService: ReturnType<typeof SegmentMarketCodeService.getMarketCodesService>;
}

export const initSegmentMarketCodeService = (
  axios: AxiosInstance
): SegmentMarketCodeServiceModel => ({
  getAll: SegmentMarketCodeService.getAll(axios),
  create: SegmentMarketCodeService.create(axios),
  update: SegmentMarketCodeService.update(axios),
  remove: SegmentMarketCodeService.remove(axios),
  toggleStatus: SegmentMarketCodeService.toggleStatus(axios),
  getMarketCodesService: SegmentMarketCodeService.getMarketCodesService(axios),
});
