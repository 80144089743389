import {
  RESERVATION_FINANCIAL_ACCOUNT_ADD_BILL_PATH,
  RESERVATION_FINANCIAL_ACCOUNT_BILL_PAYMENTS_TRANSFERT,
  RESERVATION_FINANCIAL_ACCOUNT_BILL_PAYMENTS_TRANSFERT_MULTIPLE,
  RESERVATION_FINANCIAL_ACCOUNT_BILL_POST_PAYMENT,
  RESERVATION_FINANCIAL_ACCOUNT_BILL_TRANSFER_TRANSACTION_TO_COMPANY,
  RESERVATION_FINANCIAL_ACCOUNT_CHARGES_SEARCH,
  RESERVATION_FINANCIAL_ACCOUNT_DELETE_BILL_PATH,
  RESERVATION_FINANCIAL_ACCOUNT_EDIT_CHARGE_PATH,
  RESERVATION_FINANCIAL_ACCOUNT_HEADER_PATH,
  RESERVATION_FINANCIAL_ACCOUNT_POST_TRANSACTION_ARACCOUNT_PATH,
  RESERVATION_FINANCIAL_ACCOUNT_POST_TRANSACTION_PATH,
  RESERVATION_FINANCIAL_ACCOUNT_TRANSFER_CHARGE_PATH,
  RESERVATION_FINANCIAL_ACCOUNT_TRANSFER_MULTIPLE_CHARGES_PATH,
} from '@constants/ApiEndpoints/GroupReservationEndpoint';
import { AxiosInstance } from 'axios';
import { PostTransactionData } from 'services/Reservations/FinancialReservation';
import { sprintf } from 'sprintf-js';
import { FinancialAccountPostPaymentDataModel } from '../models/FinancialAccountPostPaymentDataModel';
import { FinancialAccountReponseApiModel } from '../models/FinancialAccountReponseDataModel';
import { FinancialAccountSearchResaBillResponseModel } from '../models/FinancialAccountSearchResaBillResponseModel';
import {
  FinancialAccountChargeTransferParamsModel,
  FinancialAccountPaymentTransferParamsModel,
} from '../models/FinancialAccountTransferParamsModel';

/**
 *
 * @param axios
 * @returns
 */
export const getData =
  (axios: AxiosInstance) =>
  async (params?: {
    guestId: number;
    accountNumber: number;
  }): Promise<FinancialAccountReponseApiModel> => {
    const response = await axios.get(
      sprintf(RESERVATION_FINANCIAL_ACCOUNT_HEADER_PATH, params?.guestId, params?.accountNumber)
    );
    return response.data;
  };

/**
 *
 * @param axios
 * @returns
 */
export const addNewBill =
  (axios: AxiosInstance) =>
  async (data: {
    financialAccount: number;
    payload: { resource_id: number; resource_type: string };
  }) => {
    return await axios
      .post(sprintf(RESERVATION_FINANCIAL_ACCOUNT_ADD_BILL_PATH, data?.financialAccount), {
        resource_id: data?.payload?.resource_id,
        resource_type: data?.payload?.resource_type,
      })
      .then(({ data }) => data);
  };

/**
 *
 * @param axios
 * @returns
 */
export const postTransaction =
  (axios: AxiosInstance) =>
  async (payload: {
    reservationRoomId: number | string;
    billId: number;
    arAcount?: number;
    data: PostTransactionData;
  }) => {
    return await axios
      .post(
        sprintf(
          payload?.arAcount
            ? RESERVATION_FINANCIAL_ACCOUNT_POST_TRANSACTION_ARACCOUNT_PATH
            : RESERVATION_FINANCIAL_ACCOUNT_POST_TRANSACTION_PATH,
          payload?.reservationRoomId,
          payload?.billId,
          payload?.arAcount
        ),
        { ...payload?.data }
      )
      .then(({ data }) => data);
  };
export const postPayment =
  (axios: AxiosInstance) =>
  async ({
    data,
    reservationRoomId,
    billId,
  }: {
    reservationRoomId: number;
    billId: number;
    data: FinancialAccountPostPaymentDataModel;
  }) => {
    return await axios
      .post(sprintf(RESERVATION_FINANCIAL_ACCOUNT_BILL_POST_PAYMENT, reservationRoomId, billId), {
        ...data,
      })
      .then(({ data }) => data);
  };

export const transferCharge =
  (axios: AxiosInstance) => async (payload: FinancialAccountChargeTransferParamsModel) => {
    return await axios
      .patch(
        sprintf(
          RESERVATION_FINANCIAL_ACCOUNT_TRANSFER_MULTIPLE_CHARGES_PATH,
          payload?.identifier,
          payload?.billId
        ),
        { ...payload?.data }
      )
      .then(({ data }) => data);
  };

export const transferPayment =
  (axios: AxiosInstance) =>
  async (params: FinancialAccountPaymentTransferParamsModel): Promise<any> => {
    return await axios
      .patch(
        sprintf(
          RESERVATION_FINANCIAL_ACCOUNT_BILL_PAYMENTS_TRANSFERT_MULTIPLE,
          params.identifier,
          params.billId
        ),
        params.data
      )
      .then(({ data }) => data);
  };

export const billSearch =
  (axios: AxiosInstance) =>
  async (
    reservationRoomId: number,
    search: string
  ): Promise<FinancialAccountSearchResaBillResponseModel[]> => {
    return search !== ''
      ? await axios
          .get(sprintf(RESERVATION_FINANCIAL_ACCOUNT_CHARGES_SEARCH, reservationRoomId), {
            params: { search },
          })
          .then(({ data }) => data)
      : [];
  };

export const editCharge =
  (axios: AxiosInstance) =>
  async (
    identifier: number | string,
    billId: number,
    transactionId: number,
    payload: {
      data: {
        bill_id: number;
        amount: number;
        description: string;
      };
    }
  ) => {
    return await axios
      .patch(
        sprintf(RESERVATION_FINANCIAL_ACCOUNT_EDIT_CHARGE_PATH, identifier, billId, transactionId),
        payload.data
      )
      .then(({ data }) => data);
  };

// // Bill Services
// export const getBills = (axios: AxiosInstance) => async (identifier: number | string) => {
//   return await axios
//     .get(sprintf(RESERVATION_FINANCIAL_ACCOUNT_BILLS_PATH, identifier))
//     .then(({ data }) => data);
// };

export const deleteBill =
  (axios: AxiosInstance) => async (data: { identifier: number | string; billId: number }) => {
    return await axios
      .delete(
        sprintf(RESERVATION_FINANCIAL_ACCOUNT_DELETE_BILL_PATH, data?.identifier, data?.billId)
      )
      .then(({ data }) => data);
  };

export const transferTransactionsToCompany =
  (axios: AxiosInstance) =>
  async ({ identifier, billId }: { identifier: number | string; billId: number }) => {
    return await axios
      .patch(
        sprintf(
          RESERVATION_FINANCIAL_ACCOUNT_BILL_TRANSFER_TRANSACTION_TO_COMPANY,
          identifier,
          billId
        )
      )
      .then(({ data }) => data);
  };

// export const editCharge =
//   (axios: AxiosInstance) =>
//   async (
//     identifier: number | string,
//     billId: number,
//     transactionId: number,
//     payload: {
//       data: EditTransactionData;
//       identifier: number | string;
//       billId: number;
//       transactionId: number;
//     }
//   ) => {
//     return await axios
//       .patch(
//         sprintf(RESERVATION_FINANCIAL_ACCOUNT_EDIT_CHARGE_PATH, identifier, billId, transactionId),
//         payload
//       )
//       .then(({ data }) => data);
//   };

// export const editPayment =
//   (axios: AxiosInstance) =>
//   async (
//     identifier: number | string,
//     billId: number,
//     paymentId: number,
//     data: EditTransactionData
//   ) => {
//     return await axios
//       .patch(
//         sprintf(RESERVATION_FINANCIAL_ACCOUNT_EDIT_PAYMENT_PATH, identifier, billId, paymentId),
//         data
//       )
//       .then(({ data }) => data);
//   };

// export const transferPayment =
//   (axios: AxiosInstance) =>
//   async (params: {
//     identifier: number | string;
//     billId: number;
//     paymentId: number;
//     data: TransferData;
//   }): Promise<any> => {
//     return await axios
//       .patch(
//         sprintf(
//           RESERVATION_FINANCIAL_ACCOUNT_BILL_PAYMENTS_TRANSFERT,
//           params.identifier,
//           params.billId,
//           params.paymentId
//         ),
//         params.data
//       )
//       .then(({ data }) => data);
//   };
