import { GlobalSettingsModel } from 'models/Shared/GlobalSettingsModel';
import { ArrivalReportFormModel, ArrivalReportStateEnum } from '../../models';

export const getDefaultValues = (globalSettings: GlobalSettingsModel): ArrivalReportFormModel => ({
  from: globalSettings?.frontend_date,
  to: null,
  card_ids: [],
  rate_ids: [],
  room_type_ids: [],
  price: false,
  state: ArrivalReportStateEnum.ARRIVAL,
  nationality_ids: null,
});
