import { ACTIVE_ITEMS } from '@constants/activeItems';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { RootContext } from 'components/RootContainer';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';

export const StateField: FC<{
  value: number | null;
  onChange: (value: number | null) => void;
  error?: ReactNode;
}> = ({ value, onChange, error }) => {
  const {
    HotelApi: { settings: SettingsService },
  } = useContext(RootContext);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['currency'],
    queryFn: () =>
      SettingsService.reservation.reservationState.getAll({
        search: [ACTIVE_ITEMS],
      }),
    enabled: true,
  });

  if (isLoading) {
    return <>...</>;
  }

  return (
    <FormControl variant="standard" fullWidth className="background">
      <InputLabel id="reservation-filter-room-types">State</InputLabel>
      <Select
        error={Boolean(error)}
        labelId="reservation-filter-room-types"
        value={value}
        onChange={e => {
          onChange(e.target.value ? Number(e.target.value) : null);
        }}
      >
        <MenuItem firstItem value="all"></MenuItem>
        {(data || [])?.map((item, key) => (
          <MenuItem key={`room-type-key-${key}`} value={item.id!}>
            {`${item.name}`}
          </MenuItem>
        ))}
      </Select>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
