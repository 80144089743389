import { Router } from '@remix-run/router';
import { RootContext } from 'components/RootContainer';
import { FC, memo, useContext, useEffect, useMemo } from 'react';
import { NavigationType, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routePaths } from './routes';

export const Routing: FC = (): JSX.Element => {
  const Api = useContext(RootContext);
  const routeItems = useMemo(() => routePaths(Api), [Api]);

  const routes = useMemo(
    () =>
      createBrowserRouter(routeItems, {
        basename: '/',
      }),
    [routeItems]
  );

  useEffect(() => {
    routes.subscribe(state => {
      if (state.historyAction === NavigationType.Pop) {
        // console.log("...............................go back");
      }
    });
  }, []);

  return <RouterProviderMemo routes={routes} />;
};

const RouterProviderMemo = memo((props: { routes: Router }) => {
  return <RouterProvider router={props.routes} />;
});
