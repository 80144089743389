import {
  GROUP_RESERVATION_DETAILS_PATH,
  GROUP_RESERVATION_PATH,
  GROUP_RESERVATION_ROOMS_PATH,
  GROUP_RESERVATION_SAVE_SPLITED_PATH,
  GROUP_RESERVATION_SPLIT_PATH,
  GROUP_RESERVATION_UPDATE_PATH,
} from '@constants/ApiEndpoints/GroupReservationEndpoint';
import { AxiosInstance } from 'axios';
import { FetchingRequestParamsModels } from 'models/Shared/FetchingRequestParamsModels';
import { GroupReservationItemModel } from 'pages/ReservationModule/pages/GroupReservationPage/List/models/GroupReservationListModel';
import { sprintf } from 'sprintf-js';
import { RoomingListItemModel } from '../../../pages/ReservationModule/pages/GroupReservationPage/models/RoomingListModel';
import { GroupReservationRoomsRequest, GroupReservationUpdateRequest } from './models';

interface Market {
  id: number;
  name: string;
}

interface Agency {
  id: number;
  name: string;
}

export interface GroupReservationResponse {
  id: string;
  name: string;
  arrival: string;
  departure: string;
  state: string;
  market: Market;
  agency: Agency;
  created_at: string;
  updated_at: string;
}

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: FetchingRequestParamsModels
  ): Promise<{
    items: GroupReservationItemModel[];
    total: number;
  }> => {
    const response = await axios.get(GROUP_RESERVATION_PATH, {
      params,
    });
    return response.data;
  };

export const getById =
  (axios: AxiosInstance) =>
  async (reservationId: string | number): Promise<GroupReservationResponse> => {
    const response = await axios.get(sprintf(GROUP_RESERVATION_DETAILS_PATH, reservationId));
    return response.data;
  };

export const updateRooms =
  (axios: AxiosInstance) =>
  async (reservationId: string | number, data: GroupReservationRoomsRequest): Promise<void> => {
    await axios.post(sprintf(GROUP_RESERVATION_ROOMS_PATH, reservationId), data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: GroupReservationUpdateRequest
  ): Promise<(GroupReservationUpdateRequest & { id: number }) | undefined> => {
    const response = await axios.post(GROUP_RESERVATION_UPDATE_PATH, data);
    return response.data;
  };

export const splitGroup =
  (axios: AxiosInstance) =>
  async (reservationId: string | number): Promise<RoomingListItemModel[]> => {
    const response = await axios.get(sprintf(GROUP_RESERVATION_SPLIT_PATH, reservationId));
    return response.data;
  };

export const saveRooming =
  (axios: AxiosInstance) =>
  async (reservationId: string | number, data: RoomingListItemModel[]): Promise<void> => {
    await axios.post(sprintf(GROUP_RESERVATION_SAVE_SPLITED_PATH, reservationId), {
      rooms: data,
    });
  };
