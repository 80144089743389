import { AxiosInstance } from 'axios';
import * as service from './services';

export interface FinancialAccountListingServiceType {
  getAll: ReturnType<typeof service.getAll>;
  search: ReturnType<typeof service.search>;
}

export const initFinancialAccountListingService = (
  axios: AxiosInstance
): FinancialAccountListingServiceType => ({
  getAll: service.getAll(axios),
  search: service.search(axios),
});
