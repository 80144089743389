import { ReservationListReportFormModel, ReservationListReportRequestModel } from '../models';

export const dataFormToRequest = (
  data: ReservationListReportFormModel
): ReservationListReportRequestModel => {
  return {
    from: data.from,
    to: data.to,
    currency_id: data.currency ? Number(data.currency.id) : null,
    fixed_at: data?.fixed_at ? Number(data?.fixed_at) : null,
    rates: (data?.rates || []).map(item => Number(item)),
    room_type_id: data?.roomType,
    state_id: data?.state_id,
    card_id: data?.company?.id || null,
    ...(['allocation', 'garantie'].includes(data?.sub_filter) && { type: data?.sub_filter }),
  };
};
