import React from "react";
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from "react-router-dom";

export const NavLinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterNavLinkProps, "to"> & {
    href: RouterNavLinkProps["to"];
  }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterNavLink ref={ref} to={href} {...other} />;
});
