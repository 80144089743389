import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, ReactNode } from 'react';
import { CurrencyApplicationEnum, ReservationListReportFormModel } from '../../../models';
import { useFormContext } from 'react-hook-form';

export const CurrencyApplicationField: FC<{
  value: CurrencyApplicationEnum | null;
  onChange: (value: CurrencyApplicationEnum | null) => void;
  error?: ReactNode;
}> = ({ value, onChange, error }) => {
  const { watch } = useFormContext<ReservationListReportFormModel>();
  const selectedCurrency = watch('currency');
  const disabled = selectedCurrency === null || selectedCurrency?.is_local === true;

  return (
    <FormControl variant="standard" fullWidth disabled={disabled}>
      <InputLabel id="reservation-filter-room-types">APPLICATION</InputLabel>
      <Select
        error={Boolean(error)}
        labelId="reservation-filter-room-types"
        value={value ?? null}
        onChange={e => {
          onChange(e.target.value as CurrencyApplicationEnum | null);
        }}
      >
        <MenuItem firstItem value="all"></MenuItem>
        {(Object.values(CurrencyApplicationEnum) || [])?.map((item, key) => (
          <MenuItem key={`room-type-key-${key}`} value={item}>
            {`${item}`}
          </MenuItem>
        ))}
      </Select>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
