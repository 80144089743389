import { ReservationCardGroupModel } from '../../../../../../ReservationModule/pages/CreateReservationPageNew/models';

export interface ArrivalReportFormModel {
  from: string | null;
  to: string | null;
  rate_ids: Array<number>;
  room_type_ids: Array<number>;
  card_ids: ReservationCardGroupModel[];
  state?: ArrivalReportStateEnum;
  price: boolean;
  nationality_ids: number[] | null;
}

export enum ArrivalReportStateEnum {
  ARRIVAL = 'arrival',
  CHECKED_IN = 'checked_in',
}
