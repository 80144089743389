import { AxiosInstance } from 'axios';
import {
  CustomReservationServiceModel,
  initCustomReservationService,
} from './CustomReservationService';
import { ReservationProofServiceModel, initReservationProofService } from './ProofService';
import {
  ReservationActionsServiceModel,
  initReservationActionsService,
} from './ReservationActionsService';
import { ReservationAddonServiceModel, initReservationAddonService } from './ReservationAddon';
import {
  ReservationBillingInformationsServiceModel,
  initReservationBillingInformationsService,
} from './ReservationBillingInformationsService';
import { ReservationCommonServiceModel, initReservationCommonService } from './ReservationCommon';
import {
  ReservationGeneralInformationsServiceModel,
  initReservationGeneralInformationsService,
} from './ReservationGeneralInformationsService';
import {
  ReservationNotesServiceModel,
  initReservationNotesService,
} from './ReservationNotesService';
import {
  ReservationPaymentTypeService,
  initReservationPaymentTypeService,
} from './ReservationPaymentType';
import {
  ReservationSummaryServiceModel,
  initReservationSummaryService,
} from './ReservationSummary';
import {
  ReservationRoomsAndRatesService,
  initReservationRoomsAndRatesService,
} from './RoomsAndRates';
import { ReservationStayCardServiceModel, initReservationStayCardServiceModel } from './StayCard';

export interface ReservationReservationService {
  common: ReservationCommonServiceModel;
  generalInformations: ReservationGeneralInformationsServiceModel;
  roomsAndRates: ReservationRoomsAndRatesService;
  addons: ReservationAddonServiceModel;
  summary: ReservationSummaryServiceModel;
  customReservation: CustomReservationServiceModel;
  billingInformations: ReservationBillingInformationsServiceModel;
  proof: ReservationProofServiceModel;
  stayCard: ReservationStayCardServiceModel;
  actions: ReservationActionsServiceModel;
  notes: ReservationNotesServiceModel;
  paymentTypes: ReservationPaymentTypeService;
}

export const initReservationReservationService = (
  axios: AxiosInstance
): ReservationReservationService => ({
  common: initReservationCommonService(axios),
  generalInformations: initReservationGeneralInformationsService(axios),
  roomsAndRates: initReservationRoomsAndRatesService(axios),
  addons: initReservationAddonService(axios),
  summary: initReservationSummaryService(axios),
  customReservation: initCustomReservationService(axios),
  billingInformations: initReservationBillingInformationsService(axios),
  proof: initReservationProofService(axios),
  stayCard: initReservationStayCardServiceModel(axios),
  actions: initReservationActionsService(axios),
  notes: initReservationNotesService(axios),
  paymentTypes: initReservationPaymentTypeService(axios),
});
