import { GlobalSettingsModel } from 'models/Shared/GlobalSettingsModel';

export const getDefaultValues = (globalSettings: GlobalSettingsModel) => ({
  from: globalSettings?.frontend_date,
  to: null,
  company: null,
  rates: [],
  roomType: null,
  with_revenue: false,
  currency: null,
  currency_application: null,
  fixed_at: '',
  state_id: null,
});
