import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CashRegisterReportFormModel } from '../../models';
import { FilterForm } from './FilterForm';
import { schema } from './schema';

export const FilterFormProvider: FC<{
  defaultValues: CashRegisterReportFormModel;
  onSubmit: (data: CashRegisterReportFormModel) => void;
}> = ({ defaultValues, onSubmit }) => {
  const form = useForm<CashRegisterReportFormModel>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  return (
    <FormProvider {...form}>
      <FilterForm onSubmit={onSubmit} />
    </FormProvider>
  );
};
