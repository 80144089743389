import { AxiosInstance } from 'axios';
import {
  CashRegisterReportServiceType,
  initCashRegisterReportService,
} from '../pages/CashRegisterReportPage/api';

export interface CashierReportServiceType {
  cashRegisterReport: CashRegisterReportServiceType;
}

export const initCashierReportService = (axios: AxiosInstance): CashierReportServiceType => ({
  cashRegisterReport: initCashRegisterReportService(axios),
});
