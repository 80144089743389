import { ErrorMessage } from '@hookform/error-message';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Error } from 'components/Common/Error';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DATE_FORMAT_API } from '../../../../../../../../@constants/date';
import DateRangePicker from '../../../../../../../../components/DateRangePicker';
import { Calendar2Icon } from '../../../../../../../../components/SvgIcons/Calendar2Icon';
import { ReservationListReportFormModel, SubFilterEnum } from '../../models';
import { CompanyField } from './fields/CompanyField';
import { CurrencyApplicationField } from './fields/currencyApplicationField';
import { CurrencyField } from './fields/CurrencyField';
import { FixedAtField } from './fields/FixedAtField';
import { RateField } from './fields/RateField';
import { RoomTypeField } from './fields/RoomTypeField';
import { StateField } from './fields/StateField';

export const FilterForm: FC<{
  onSubmit: (data: ReservationListReportFormModel) => void;
}> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<ReservationListReportFormModel>();

  const formValues = watch();

  const dates = useMemo(
    (): (Date | null)[] => [
      formValues?.from ? new Date(formValues?.from) : null,
      formValues?.to ? new Date(formValues?.to) : null,
    ],
    [formValues?.from, formValues?.to]
  );

  const handleChangeDates = (value: (Date | null)[]) => {
    const [from, to] = value;
    const _from = from ? format(from, DATE_FORMAT_API) : null;
    const _to = to ? format(to, DATE_FORMAT_API) : null;
    setValue('from', _from);
    setValue('to', _to);
  };

  return (
    <Box sx={{ p: 0, height: '100%', backgroundColor: 'colors.gray.o20' }}>
      <Stack direction={'column'} spacing={0} sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ height: 80, backgroundColor: 'colors.gray.o10', p: 2 }}>
          <Stack direction={'row'} alignItems={'center'} sx={{ height: '100%' }}>
            <Typography
              align="center"
              color="secondary"
              variant="inter20"
              fontWeight={'fontWeightBold'}
            >
              Forecast By RoomType
            </Typography>
          </Stack>
        </Box>
        <Stack direction={'row'} spacing={0}>
          {/******************************** LEFT BLOC ********************************/}

          <Box sx={{ width: '40%', p: 4, backgroundColor: 'colors.blue.o10' }}>
            <Stack direction={'column'} spacing={2}>
              <Box sx={{ height: 48, display: 'flex', alignItems: 'center', mt: 1 }}>
                <Controller
                  control={control}
                  name="with_revenue"
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ m: 0 }}
                      {...field}
                      checked={field.value ?? false}
                      onChange={(e, checked) => field.onChange(checked)}
                      label={
                        <Typography variant="inter16" fontWeight={500} sx={{ ml: '1rem' }}>
                          With Revenue
                        </Typography>
                      }
                      control={<Switch />}
                    />
                  )}
                />
              </Box>

              <Controller
                control={control}
                name="currency"
                render={({ field, formState }) => (
                  <CurrencyField
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    error={
                      formState?.errors[field.name] ? (
                        <ErrorMessage
                          errors={formState.errors}
                          name={field.name}
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      ) : null
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="currency_application"
                render={({ field, formState }) => (
                  <CurrencyApplicationField
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    error={
                      formState?.errors[field.name] ? (
                        <ErrorMessage
                          errors={formState.errors}
                          name={field.name}
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      ) : null
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="fixed_at"
                render={({ field, formState }) => (
                  <FixedAtField
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    helperText={
                      <>
                        <ErrorMessage
                          errors={formState.errors}
                          name={field.name}
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </>
                    }
                  />
                )}
              />
            </Stack>
          </Box>
          {/******************************** RIGHT BLOC ********************************/}
          <Box sx={{ width: '60%', p: 3, backgroundColor: '#fff' }}>
            <Stack direction={'column'} spacing={2}>
              <Stack direction={'row'} spacing={2} width={'100%'}>
                <Box sx={{ width: '100%' }}>
                  <DateRangePicker
                    value={dates}
                    onChange={handleChangeDates}
                    renderInput={({ startProps, endProps }) => (
                      <Stack
                        direction="row"
                        spacing="1rem"
                        sx={{
                          width: '100%',
                        }}
                      >
                        <TextField
                          {...startProps}
                          size="medium"
                          variant="standard"
                          sx={{
                            width: '50%',
                          }}
                          className="background"
                          label="From"
                          error={Boolean(errors?.from)}
                          helperText={errors?.from?.message}
                          placeholder="mm/dd/yyyy"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Calendar2Icon
                                  sx={{
                                    fontSize: '1rem',
                                    color: ({ palette }) => palette.common.black,
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          {...endProps}
                          size="medium"
                          variant="standard"
                          sx={{
                            width: '50%',
                          }}
                          className="background"
                          label="To"
                          error={Boolean(errors?.to)}
                          helperText={errors?.to?.message}
                          placeholder="mm/dd/yyyy"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Calendar2Icon
                                  sx={{
                                    fontSize: '1rem',
                                    color: ({ palette }) => palette.common.black,
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    )}
                  />
                </Box>
              </Stack>

              <Stack direction={'row'} spacing={2}>
                <Box sx={{ width: '50%' }}>
                  <Controller
                    control={control}
                    name="company"
                    render={({ field }) => (
                      <CompanyField value={field.value} onChange={value => field.onChange(value)} />
                    )}
                  />
                </Box>

                <Controller
                  control={control}
                  name="rates"
                  render={({ field, formState }) => (
                    <RateField
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      error={
                        formState?.errors[field.name] ? (
                          <ErrorMessage
                            errors={formState.errors}
                            name={field.name}
                            render={({ message }) => <Error>{message}</Error>}
                          />
                        ) : null
                      }
                    />
                  )}
                />
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Box sx={{ width: '50%' }}>
                  <Controller
                    control={control}
                    name="roomType"
                    render={({ field }) => (
                      <RoomTypeField
                        value={field.value}
                        onChange={value => field.onChange(value)}
                      />
                    )}
                  />
                </Box>

                <Box sx={{ width: '50%' }}>
                  <Controller
                    control={control}
                    name="state_id"
                    render={({ field }) => (
                      <StateField value={field.value} onChange={value => field.onChange(value)} />
                    )}
                  />
                </Box>
              </Stack>
              <FormControl>
                <InputLabel sx={{ mt: 3 }}>Type</InputLabel>
                <Controller
                  control={control}
                  name="sub_filter"
                  render={({ field }) => (
                    <RadioGroup
                      sx={{ mt: 3 }}
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={field.value}
                      onChange={value => field.onChange(value)}
                    >
                      <FormControlLabel
                        value={SubFilterEnum.NONE}
                        control={<Radio />}
                        label="None"
                      />
                      <FormControlLabel
                        value={SubFilterEnum.ALLOCATION}
                        control={<Radio />}
                        label="Allocation"
                      />
                      <FormControlLabel
                        value={SubFilterEnum.GUARANTEE}
                        control={<Radio />}
                        label="Guarantee"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <Box sx={{ height: 10 }} />

              <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
                Exporter en PDF
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
