import {
  RESERVATION_FINANCIAL_ACCOUNT_CHARGES_SEARCH,
  RESERVATION_FINANCIAL_ACCOUNT_PATH,
} from '@constants/ApiEndpoints/GroupReservationEndpoint';
import { AxiosInstance } from 'axios';
import { sprintf } from 'sprintf-js';

import {
  FinancialAccountListingResponseModel,
  FinancialAccountListItemModel,
} from '../models/FinancialAccountListItemModel';

/**
 *
 * @param axios
 * @returns
 */
export const getAll =
  (axios: AxiosInstance) =>
  async (params?: {
    search: string;
    from?: string;
    to?: string;
  }): Promise<FinancialAccountListingResponseModel> => {
    const response = await axios.get(RESERVATION_FINANCIAL_ACCOUNT_PATH, {
      params,
    });
    return response.data;
  };

/**
 *
 * @param axios
 * @returns
 */
export const search =
  (axios: AxiosInstance) =>
  async (reservationRoomId: number, search: string): Promise<FinancialAccountListItemModel[]> => {
    return await axios
      .get(sprintf(RESERVATION_FINANCIAL_ACCOUNT_CHARGES_SEARCH, reservationRoomId), {
        params: { search },
      })
      .then(({ data }) => data?.items);
  };
