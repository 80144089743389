import { FC, useEffect } from 'react';
import { CurrencyApplicationEnum, ReservationListReportFormModel } from '../../models';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { FilterForm } from './FilterForm';
import { useDependenciesValues } from '../../hooks/useDependenciesValues';

export const FilterFormProvider: FC<{
  defaultValues: ReservationListReportFormModel;
  onSubmit: (data: ReservationListReportFormModel) => void;
}> = ({ defaultValues, onSubmit }) => {
  const form = useForm<ReservationListReportFormModel>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  /*******************************  dependencies values *******************************/

  useDependenciesValues(form);

  /*******************************  dependencies values *******************************/

  return (
    <FormProvider {...form}>
      <FilterForm onSubmit={onSubmit} />
    </FormProvider>
  );
};
