import { ACTIVE_ITEMS } from '@constants/activeItems';
import { FormControl, InputLabel, Select } from '@mui/material';
import { RootContext } from 'components/RootContainer';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const CashierField: FC<{
  value: number | null;
  onChange: (value: number | null) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation('reservation');

  const {
    HotelApi: {
      settings: {
        financial: {
          cashier: { getAll },
        },
      },
    },
  } = useContext(RootContext);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['get-cashiers'],
    queryFn: () => {
      return getAll({
        search: [ACTIVE_ITEMS],
      });
    },
  });

  if (isLoading) {
    return <>...</>;
  }

  return (
    <FormControl variant="standard" fullWidth className="background">
      <InputLabel id="reservation-filter-cashiers">Cashiers</InputLabel>
      <Select
        labelId="reservation-filter-cashiers"
        value={value || ''}
        onChange={event => onChange(Number(event.target.value))}
      >
        <MenuItem firstItem value="all">
          {t('list.filter.all')}
        </MenuItem>
        {(data?.items || [])?.map((cashier, key) => (
          <MenuItem key={`cashier-key-${key}`} value={cashier.id!}>
            {cashier?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
