import { AxiosInstance } from 'axios';
import * as ReservationListReportService from './services';

export type ReservationListReportServiceType = ReturnType<
  typeof ReservationListReportService.exportPDF
>;

export const initReservationListReportService = (
  axios: AxiosInstance
): ReservationListReportServiceType => ReservationListReportService.exportPDF(axios);
