import { ReservationsEndpoint } from "@constants/ApiEndpoints";
import { AxiosInstance } from "axios";
import { CardGroupModel } from "models/Reservations/CardGroup";

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: any): Promise<CardGroupModel[]> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_GROUP_CARD, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const getAllPaginated =
  (axios: AxiosInstance) =>
  async (
    params?: any
  ): Promise<{ items: CardGroupModel[]; total: number }> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_GROUP_CARD, {
        params,
      })
      .then(({ data }) => data);
  };

export const getOneById =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CardGroupModel> => {
    return await axios
      .get(`${ReservationsEndpoint.RESERVATION_GROUP_CARD}/${id}`)
      .then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: CardGroupModel): Promise<CardGroupModel> => {
    return await axios
      .post(ReservationsEndpoint.RESERVATION_GROUP_CARD, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (
    data: CardGroupModel
  ): Promise<CardGroupModel> => {
    return await axios
      .put(
        `${ReservationsEndpoint.RESERVATION_GROUP_CARD}/${data.id}`,
        {
          ...data,
        }
      )
      .then(({ data }) => data);
  };
