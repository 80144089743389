import { FINANCIAL_ACCOUNT_CREATE_PATH } from '@constants/Navigation/AppPaths/RootPaths';
import { RootContextProps } from 'components/RootContainer';
import { Crumb } from 'components/TranslatableCrub';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

const ListingPage = lazy(() => import('../pages/ListingPage'));
const CreatePage = lazy(() => import('../pages/CreatePage'));
const DetailsPage = lazy(() => import('../pages/DetailsPage'));

const financialAccountRoutes = (api: RootContextProps): RouteObject[] => [
  {
    index: true,
    element: (
      <Suspense fallback={<>...</>}>
        <ListingPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="Financial Account" path={null} />,
    },
  },

  {
    path: FINANCIAL_ACCOUNT_CREATE_PATH,
    element: (
      <Suspense fallback={<>...</>}>
        <CreatePage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="Financial Account" />,
    },
  },

  {
    path: ':guestId/:accountNumber',
    element: (
      <Suspense fallback={<>...</>}>
        <DetailsPage />
      </Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="Financial Account . Charges And Payments" path={null} />,
    },
  },
];

export default financialAccountRoutes;
