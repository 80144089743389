import { AxiosInstance } from 'axios';
import { AccessibilityService } from 'services';

export interface AccessibilityServiceModel {
  getAll: ReturnType<typeof AccessibilityService.getAll>;
  create: ReturnType<typeof AccessibilityService.create>;
}

export const initAccessibilityService = (axios: AxiosInstance): AccessibilityServiceModel => ({
  getAll: AccessibilityService.getAll(axios),
  create: AccessibilityService.create(axios),
});
