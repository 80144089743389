import { AxiosInstance } from 'axios';
import { ArrivalReportServiceType, initArrivalReportService } from '../pages/ArrivalReportPage/api';
import { DailyReportServiceType, initDailyReportService } from '../pages/DailyReportPage/api';
import {
  DepartureReportServiceType,
  initDepartureReportService,
} from '../pages/DepartureReportPage/api';
import { InHouseReportServiceType, initInHouseReportService } from '../pages/InHouseReportPage/api';
import { LogsReportServiceType, initLogsReportService } from '../pages/LogsReportPage/api';

export interface FrontdeskReportServiceType {
  arrivalReport: ArrivalReportServiceType;
  departureReport: DepartureReportServiceType;
  inHouseReport: InHouseReportServiceType;
  dailyReport: DailyReportServiceType;
  logsReport: LogsReportServiceType;
}

export const initFrontdeskReportService = (axios: AxiosInstance): FrontdeskReportServiceType => ({
  arrivalReport: initArrivalReportService(axios),
  departureReport: initDepartureReportService(axios),
  inHouseReport: initInHouseReportService(axios),
  dailyReport: initDailyReportService(axios),
  logsReport: initLogsReportService(axios),
});
