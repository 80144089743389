import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, ReactNode } from 'react';
import { ArrivalReportStateEnum } from '../../../models';

export const StateField: FC<{
  value: ArrivalReportStateEnum | undefined;
  onChange: (value: ArrivalReportStateEnum | undefined) => void;
  error?: ReactNode;
}> = ({ value, onChange, error }) => {
  return (
    <FormControl variant="standard" fullWidth className="background">
      <InputLabel id="reservation-filter-room-types">State</InputLabel>
      <Select
        error={Boolean(error)}
        labelId="reservation-filter-room-types"
        value={value ?? 'all'}
        onChange={e => {
          onChange(e.target.value ? (e.target.value as ArrivalReportStateEnum) : undefined);
        }}
      >
        <MenuItem firstItem value="all">
          All
        </MenuItem>
        <MenuItem firstItem value={ArrivalReportStateEnum.ARRIVAL}>
          Arrival
        </MenuItem>
        <MenuItem firstItem value={ArrivalReportStateEnum.CHECKED_IN}>
          Checked in
        </MenuItem>
        {/*  */}
      </Select>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
