import { AxiosInstance } from 'axios';
import * as FourWeekForecastReportService from './services';

export type FourWeekForecastReportServiceType = ReturnType<
  typeof FourWeekForecastReportService.exportPDF
>;

export const initFourWeekForecastReportService = (
  axios: AxiosInstance
): FourWeekForecastReportServiceType => FourWeekForecastReportService.exportPDF(axios);
