import { CurrencyModel } from 'models/Settings/Financial/CurrencyModel';
import { ReservationCardGroupModel } from 'pages/ReservationModule/pages/CreateReservationPageNew/models';

export interface ReservationListReportFormModel {
  from: string | null;
  to: string | null;
  company: ReservationCardGroupModel | null;
  rates: Array<string>;
  roomType: number | null;
  with_revenue: boolean;
  currency: CurrencyModel | null;
  currency_application: CurrencyApplicationEnum | null;
  fixed_at: string | null;
  sub_filter: SubFilterEnum;
}

export enum CurrencyApplicationEnum {
  FIXED = 'fixed',
  DAILY = 'daily',
}

export interface ReservationListReportFormModel {
  from: string | null;
  to: string | null;
  company: ReservationCardGroupModel | null;
  rates: Array<string>;
  roomType: number | null;
  with_revenue: boolean;
  currency: CurrencyModel | null;
  currency_application: CurrencyApplicationEnum | null;
  fixed_at: string | null;
  state_id: number | null;
  sub_filter: SubFilterEnum;
}

export interface ReservationListReportRequestModel {
  from: string | null;
  to: string | null;
  currency_id: number | null;
  fixed_at: number | null;
  rates: Array<number>;
  room_type_id: number | null;
  state_id: number | null;
  sub_filter?: SubFilterEnum;
  card_id: number | null;
}

export enum SubFilterEnum {
  NONE = 'none',
  ALLOCATION = 'allocation',
  GUARANTEE = 'guarantee',
}
