import { SettingsEndpoint } from '@constants/ApiEndpoints';
import { AxiosInstance } from 'axios';
import { CounterApiListingModel, CounterApiModel } from 'models/Settings/Financial/CounterModel';
import { FetchingRequestParamsModels } from 'models/Shared/FetchingRequestParamsModels';

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CounterApiModel> => {
    return await axios.delete(`${SettingsEndpoint.SETTINGS_APPLICATION_COUNTER}/${id}`);
  };

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<CounterApiListingModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_APPLICATION_COUNTER, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: CounterApiModel): Promise<CounterApiModel> => {
    return await axios
      .post(SettingsEndpoint.SETTINGS_APPLICATION_COUNTER, data)
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: CounterApiModel): Promise<CounterApiModel> => {
    return await axios
      .put(`/${SettingsEndpoint.SETTINGS_APPLICATION_COUNTER}/${data.id}`, data)
      .then(({ data }) => data);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CounterApiModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_APPLICATION_COUNTER}/${id}`)
      .then(({ data }) => data);
  };
