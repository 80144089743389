import { AxiosInstance } from "axios";
import { FlashManagerService } from "services/RevenueManagement/FlashManager";

export interface FlashManagerServiceModel {
    getAll: ReturnType<typeof FlashManagerService.getAll>;
    exportPDF: ReturnType<typeof FlashManagerService.exportPDF>;

}

export const initFlashManagerService = (axios: AxiosInstance): FlashManagerServiceModel => ({
    getAll: FlashManagerService.getAll(axios),
    exportPDF: FlashManagerService.exportPDF(axios),
});
