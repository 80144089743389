import { ReservationsEndpoint } from '@constants/ApiEndpoints';
import { AxiosInstance } from 'axios';
import { FrontDeskArrivalRoomModel } from 'components/RoomSelectorDialogDialog/models';
import { ArrivalListItemModel, ArrivalListModel } from 'models/FrontDesk/ArrivalModel';
import RoomListModel from 'models/Settings/Rooms/RoomListModel';
import { FrontDeskFetchingRequestParamsModels } from 'models/Shared/FetchingRequestParamsModels';
import { sprintf } from 'sprintf-js';

interface RoomParamsModel {
  data?: {
    id: number;
    room_number?: number | string;
  };
  filters?: FiltersModel;
}

type AutoAssignManyParams = {
  room_reservation_ids: number[];
  room_type_id?: number;
  date_night?: string;
};

type AutoAssignOneParams = {
  room_reservation_id: number;
  room_type_id: number;
};

type AutoAssignResultType = {
  reservation_room_id: number | null;
  room_number: string | null;
  room_type_id?: string | null;
  name: string;
};

type AutoAssignOneResult = {
  reservation_room_id: number;
  room_number: string;
  name?: string;
};

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FrontDeskFetchingRequestParamsModels): Promise<ArrivalListModel> => {
    return await axios
      .get(ReservationsEndpoint.RESERVATION_ARRIVAL_GET_ALL, { params })
      .then(({ data }) => data);
  };

export const exportPDF =
  (axios: AxiosInstance) =>
  async (params?: FrontDeskFetchingRequestParamsModels): Promise<void> => {
    return await axios
      .get(`${ReservationsEndpoint.RESERVATION_ARRIVAL_REPPORT_PDF}`, {
        params: { timestamp: new Date().getTime(), ...params },
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
      })
      .then(response => {
        const file = new Blob([response as any], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        // Ouvrir dans un nouvel onglet
        window.open(fileURL, '_blank');
      });
  };

export const patch =
  (axios: AxiosInstance) =>
  async (data: {
    id: number;
    room_number: number | string;
    share?: boolean;
  }): Promise<ArrivalListItemModel> => {
    return await axios
      .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_UPDATE_ROOM, data?.id!), {
        room_number: data?.room_number,
        share: data?.share,
      })
      .then(({ data }) => data);
  };

export const checkin =
  (axios: AxiosInstance) =>
  async (data: { itemsIds: number[]; forced?: boolean }): Promise<ArrivalListItemModel> => {
    return await axios
      .patch(ReservationsEndpoint.RESERVATION_ARRIVAL_CHECKIN, {
        room_reservation_ids: data?.itemsIds,
        ...(data?.forced ? { forced: data?.forced } : {}),
      })
      .then(({ data }) => data);
  };

export const cancel =
  (axios: AxiosInstance) =>
  async (data: { itemsIds: number[] }): Promise<ArrivalListItemModel> => {
    return await axios
      .patch(ReservationsEndpoint.RESERVATION_ARRIVAL_CANCEL, {
        room_reservation_ids: data?.itemsIds,
      })
      .then(({ data }) => data);
  };

export const undoCheckin =
  (axios: AxiosInstance) =>
  async (data: { itemsIds: number[] }): Promise<ArrivalListItemModel> => {
    return await axios
      .patch(ReservationsEndpoint.RESERVATION_ARRIVAL_UNDO_CHECKED_IN, {
        room_reservation_ids: data?.itemsIds,
      })
      .then(({ data }) => data);
  };

export const updateReservationInfo =
  (axios: AxiosInstance) =>
  async (data: {
    arrival?: string;
    departure?: string;
    reservationIds: number[];
    checkin_time?: string;
    checkout_time?: string;
  }): Promise<ArrivalListItemModel> => {
    return await axios
      .post(ReservationsEndpoint.RESERVATION_ARRIVAL_UPDATE_RESERVATION_INFO, {
        data,
      })
      .then(({ data }) => data);
  };

export const confirm =
  (axios: AxiosInstance) =>
  async (itemsIds: number[]): Promise<ArrivalListItemModel> => {
    return await axios
      .patch(ReservationsEndpoint.RESERVATION_ARRIVAL_CONFIRM, {
        room_reservation_ids: itemsIds,
      })
      .then(({ data }) => data);
  };

export const autoAssign =
  (axios: AxiosInstance) =>
  async (itemsIds: number[]): Promise<AutoAssignResultType[]> => {
    return await axios
      .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_AUTO_ASSIGN), {
        room_reservation_ids: itemsIds,
      })
      .then(({ data }) => data);
  };

export const autoAssignMany =
  (axios: AxiosInstance) =>
  async (data: AutoAssignManyParams): Promise<AutoAssignResultType[]> => {
    return await axios
      .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_AUTO_ASSIGN_MANY), data)
      .then(({ data }) => data);
  };

export const autoAssignOne =
  (axios: AxiosInstance) =>
  async (data: AutoAssignOneParams): Promise<AutoAssignOneResult> => {
    return await axios
      .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_AUTO_ASSIGN_ONE), data)
      .then(({ data }) => data);
  };

export const updateArrival =
  (axios: AxiosInstance) =>
  async (data: { itemsIds: number[]; arrival: string }): Promise<ArrivalListItemModel> => {
    return await axios
      .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_CHECK_IN_TIME), {
        room_reservation_ids: data?.itemsIds,
        check_in_time: data?.arrival,
      })
      .then(({ data }) => data);
  };

export const updateArrivalAndAffectRoom =
  (axios: AxiosInstance) =>
  async (data: {
    checkin_time?: string;
    room_number?: string | null;
    id: number;
    share?: boolean;
    date_night?: string | undefined;
  }): Promise<RoomListModel> => {
    return await axios
      .patch(
        sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_CHECK_IN_TIME_AND_AFFECT_ROOM, data?.id),
        {
          arrival: data.checkin_time,
          ...(data?.room_number ? { room_number: data?.room_number } : {}),
          ...(data?.share ? { share: data?.share } : {}),
          ...(data?.date_night ? { date_night: data?.date_night } : {}),
        }
      )
      .then(({ data }) => data);
  };

export const getAllRoomType =
  (axios: AxiosInstance) =>
  async (params: RoomParamsModel): Promise<FrontDeskArrivalRoomModel[]> => {
    return await axios
      .get(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_UPDATE_ROOM, params?.data?.id!), {
        params: params?.filters,
      })
      .then(({ data }) => data.items);
  };

export const updateDeparture =
  (axios: AxiosInstance) =>
  async (data: { itemsIds: number[]; arrival: string }): Promise<ArrivalListItemModel> => {
    return await axios
      .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_CHECK_OUT_TIME), {
        room_reservation_ids: data?.itemsIds,
        check_in_time: data?.arrival,
      })
      .then(({ data }) => data);
  };

export const revert =
  (axios: AxiosInstance) =>
  async (data: { itemsIds: number[]; arrival: string }): Promise<ArrivalListItemModel> => {
    return await axios
      .patch(sprintf(ReservationsEndpoint.RESERVATION_ARRIVAL_REVERT), {
        room_reservation_ids: data?.itemsIds,
        check_in_time: data?.arrival,
      })
      .then(({ data }) => data);
  };

interface FiltersModel {
  room_number: string;
  room_type: string[] | null;
  states: number[] | null;
  show_departure?: boolean;
}

export enum StateEnum {
  ALL = 'all',
  CLEAN = 'clean',
  dirty = 'dirty',
}
