import { RESERVATION_ROOT_ENDPOINT } from './RootEndpoint';

/**
 * Group Reservation
 */
export const GROUP_RESERVATION_PATH = `${RESERVATION_ROOT_ENDPOINT}/group-reservations`;
export const GROUP_RESERVATION_ROOMS_PATH = `${GROUP_RESERVATION_PATH}/%d/rooms`;
export const GROUP_RESERVATION_UPDATE_PATH = `${GROUP_RESERVATION_PATH}/update`;
export const GROUP_RESERVATION_SPLIT_PATH = `${GROUP_RESERVATION_PATH}/%d/split-group`;
export const GROUP_RESERVATION_SAVE_SPLITED_PATH = `${GROUP_RESERVATION_PATH}/%d/rooms?forced=true`;
export const GROUP_RESERVATION_DETAILS_PATH = `${GROUP_RESERVATION_PATH}/%d`;

/**
 * Financial Account
 */
export const RESERVATION_FINANCIAL_ACCOUNT_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account`;
export const RESERVATION_FINANCIAL_ACCOUNT_HEADER_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%d/%d`;

/**
 * Financial Account Bills
 */
export const RESERVATION_FINANCIAL_ACCOUNT_BILLS_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills`;
export const RESERVATION_FINANCIAL_ACCOUNT_ADD_BILL_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/room/%d/bills`;
export const RESERVATION_FINANCIAL_ACCOUNT_DELETE_BILL_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/%d`;
export const RESERVATION_FINANCIAL_ACCOUNT_POST_TRANSACTION_ARACCOUNT_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/post/%s/transaction/%s`;
export const RESERVATION_FINANCIAL_ACCOUNT_POST_TRANSACTION_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/post/%s/transaction`;
export const RESERVATION_FINANCIAL_ACCOUNT_BILL_POST_PAYMENT = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/post/%s/payment`;

export const RESERVATION_FINANCIAL_ACCOUNT_CHARGES_SEARCH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bill`;

export const RESERVATION_FINANCIAL_ACCOUNT_EDIT_CHARGE_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/%d/charges/%d/edit`;
export const RESERVATION_FINANCIAL_ACCOUNT_EDIT_PAYMENT_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/%d/payments/%d/edit`;
export const RESERVATION_FINANCIAL_ACCOUNT_TRANSFER_CHARGE_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/%d/charges/%d/transfer`;
export const RESERVATION_FINANCIAL_ACCOUNT_BILL_TRANSFER_TRANSACTION_TO_COMPANY = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/%d/charges/transfer`;
export const RESERVATION_FINANCIAL_ACCOUNT_BILL_PAYMENTS_TRANSFERT = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/%d/payments/%d/transfer`;

export const RESERVATION_FINANCIAL_ACCOUNT_TRANSFER_MULTIPLE_CHARGES_PATH = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/%d/charges/multiple/transfer`;
export const RESERVATION_FINANCIAL_ACCOUNT_BILL_PAYMENTS_TRANSFERT_MULTIPLE = `${RESERVATION_ROOT_ENDPOINT}/financial-account/%s/bills/%d/payments/multiple/transfer`;
