import { DATE_FORMAT_API, DATE_FORMAT_APP } from "@constants/date";
import { format, parse } from "date-fns";

type DateType = Date | null | string | number | undefined;

export function inputDateValue(date: DateType): Date {
  if (!date) return new Date();
  return new Date(date);
}

export function outputDateValue(date: DateType): string {
  if (!date) return "";


  return format(new Date(date), DATE_FORMAT_API);
}

export function outputDateView(date: DateType): string {
  if (!date) return "";

  return format(new Date(date), DATE_FORMAT_APP);
}

export const transformYupDate = (
  value: DateType,
  originalValue: DateType
): Date => {
  return parse(outputDateValue(originalValue), DATE_FORMAT_API, new Date());
};
