import { Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DATE_FORMAT_API } from '../../../../../../../../@constants/date';
import DateRangePicker from '../../../../../../../../components/DateRangePicker';
import { Calendar2Icon } from '../../../../../../../../components/SvgIcons/Calendar2Icon';
import { CashRegisterReportFormModel } from '../../models';
import { CashRegisterField } from './fields/CashRegisterField';
import { CashierField } from './fields/CashierField';

export const FilterForm: FC<{
  onSubmit: (data: CashRegisterReportFormModel) => void;
}> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<CashRegisterReportFormModel>();

  const formValues = watch();

  const dates = useMemo(
    (): (Date | null)[] => [
      formValues?.from ? new Date(formValues?.from) : null,
      formValues?.to ? new Date(formValues?.to) : null,
    ],
    [formValues?.from, formValues?.to]
  );

  const handleChangeDates = (value: (Date | null)[]) => {
    const [from, to] = value;
    const _from = from ? format(from, DATE_FORMAT_API) : null;
    const _to = to ? format(to, DATE_FORMAT_API) : null;
    setValue('from', _from);
    setValue('to', _to);
  };

  return (
    <Box sx={{ p: 0, height: '100%', backgroundColor: 'colors.gray.o20' }}>
      <Stack direction={'column'} spacing={0} sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ height: 80, backgroundColor: 'colors.gray.o10', p: 2 }}>
          <Stack direction={'row'} alignItems={'center'} sx={{ height: '100%' }}>
            <Typography
              align="center"
              color="secondary"
              variant="inter20"
              fontWeight={'fontWeightBold'}
            >
              Arrival Report
            </Typography>
          </Stack>
        </Box>
        <Stack direction={'row'} spacing={0}>
          <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#fff' }}>
            <Stack direction={'column'} spacing={2}>
              <DateRangePicker
                value={dates}
                onChange={handleChangeDates}
                renderInput={({ startProps, endProps }) => (
                  <Stack
                    direction="row"
                    spacing="1rem"
                    sx={{
                      width: '100%',
                    }}
                  >
                    <TextField
                      {...startProps}
                      size="medium"
                      variant="standard"
                      sx={{
                        width: '50%',
                      }}
                      className="background"
                      label="From"
                      error={Boolean(errors?.from)}
                      helperText={errors?.from?.message}
                      placeholder="mm/dd/yyyy"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar2Icon
                              sx={{
                                fontSize: '1rem',
                                color: ({ palette }) => palette.common.black,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      {...endProps}
                      size="medium"
                      variant="standard"
                      sx={{
                        width: '50%',
                      }}
                      className="background"
                      label="To"
                      error={Boolean(errors?.to)}
                      helperText={errors?.to?.message}
                      placeholder="mm/dd/yyyy"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar2Icon
                              sx={{
                                fontSize: '1rem',
                                color: ({ palette }) => palette.common.black,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                )}
              />
              <Stack direction={'row'} spacing={2}>
                <Box width={'50%'}>
                  <Controller
                    control={control}
                    name="cash_register_id"
                    render={({ field }) => (
                      <CashRegisterField
                        value={field.value}
                        onChange={value => field.onChange(value)}
                      />
                    )}
                  />
                </Box>
                <Box width={'50%'}>
                  <Controller
                    control={control}
                    name="cashier_id"
                    render={({ field }) => (
                      <CashierField value={field.value} onChange={value => field.onChange(value)} />
                    )}
                  />
                </Box>
              </Stack>
              <Box sx={{ height: 10 }} />

              <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
                Exporter en PDF
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
