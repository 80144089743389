import { AxiosInstance } from 'axios';
import { CounterService } from 'services';

export interface CounterServiceModel {
  getAll: ReturnType<typeof CounterService.getAll>;
  create: ReturnType<typeof CounterService.create>;
  update: ReturnType<typeof CounterService.update>;
  remove: ReturnType<typeof CounterService.remove>;
  toggleStatus: ReturnType<typeof CounterService.toggleStatus>;
}

export const initCounterService = (axios: AxiosInstance): CounterServiceModel => ({
  getAll: CounterService.getAll(axios),
  create: CounterService.create(axios),
  update: CounterService.update(axios),
  remove: CounterService.remove(axios),
  toggleStatus: CounterService.toggleStatus(axios),
});
