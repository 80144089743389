import { AxiosInstance } from 'axios';
import * as ForecastByRoomTypeReportService from './services';

export type ForecastByRoomTypeReportServiceType = ReturnType<
  typeof ForecastByRoomTypeReportService.exportPDF
>;

export const initForecastByRoomTypeReportService = (
  axios: AxiosInstance
): ForecastByRoomTypeReportServiceType => ForecastByRoomTypeReportService.exportPDF(axios);
