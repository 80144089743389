import { ReservationListReportFormModel, ReservationListReportRequestModel } from '../models';

export const dataFormToRequest = (
  data: ReservationListReportFormModel
): ReservationListReportRequestModel => {
  return {
    from: data.from,
    to: data.to,
    currency_id: data.currency ? Number(data.currency.id) : null,
    fixed_at: data?.fixed_at ? Number(data?.fixed_at) : null,
    date_type: data.date_type,
    rate_ids: (data?.rates || []).map(item => Number(item)),
    room_type_id: data?.roomType,
    state_id: data?.state_id,
    gross_revenue: data?.gross_revenue,
    card_id: data?.company?.id,
  };
};
