import { AxiosInstance } from 'axios';
import * as DailyForecastReportService from './services';

export type DailyForecastReportServiceType = ReturnType<
  typeof DailyForecastReportService.exportPDF
>;

export const initDailyForecastReportService = (
  axios: AxiosInstance
): DailyForecastReportServiceType => DailyForecastReportService.exportPDF(axios);
