export const BASE_PATH = '/';
export const DASHBOARD_PATH = BASE_PATH;
/***************************************/
export const RESERVATION_PATH = `${BASE_PATH}reservations`;
export const CREATE_RESERVATION_PATH = `${RESERVATION_PATH}/create`;
export const CREATE_RESERVATION_NEW_PATH = `${RESERVATION_PATH}/create-reservation`;
export const EDIT_RESERVATION_NEW_PATH = `${RESERVATION_PATH}/%s/create`;
export const FORECAST_PATH = `${RESERVATION_PATH}/forecast`;
export const GUEST_PATH = `${RESERVATION_PATH}/guest`;
export const STAYCARD_ROOT_PATH = `${RESERVATION_PATH}/stay-card`;
/***************************************/
export const GROUPS_PATH = `${BASE_PATH}groups`;
/***************************************/
export const FRONT_DESK_PATH = `${BASE_PATH}front-desk`;
export const ARRIVAL_PATH = `${FRONT_DESK_PATH}/arrival`;
export const DEPARTURE_PATH = `${FRONT_DESK_PATH}/departure`;
export const INHOUSE_PATH = `${FRONT_DESK_PATH}/inhouse`;
export const ROOM_PLAN_PATH = `${FRONT_DESK_PATH}/room-plan`;
export const FINANCIAL_ACCOUNT_LIST_PATH = `${RESERVATION_PATH}/financial-account`;
export const FINANCIAL_ACCOUNT_CHARGE_AND_PAYMENT_PATH = `${RESERVATION_PATH}/financial-account/%d/%d`;
export const FINANCIAL_ACCOUNT_CREATE_PATH = `${RESERVATION_PATH}/financial-account/create`;
export const GROUP_RESERVATION_PATH = `${RESERVATION_PATH}/group-reservation`;

/***************************************/
export const CONCIERGE_PATH = `${BASE_PATH}concierge`;
/***************************************/
export const CASHIER_ROOT_PATH = `${BASE_PATH}cashier`;
export const CASH_REGISTER_PATH = `${CASHIER_ROOT_PATH}/cash-register`;
export const RATE_EXCHANGE_PATH = `${CASHIER_ROOT_PATH}/rate-exchange`;
export const EXCHANGE_PATH = `${CASHIER_ROOT_PATH}/exchange`;
export const BILLING_PATH = `${CASHIER_ROOT_PATH}/billing`;
/***************************************/
export const REVENU_MANAGEMENT_PATH = `${BASE_PATH}revenue-management`;
export const CARDS_PATH = `${REVENU_MANAGEMENT_PATH}/cards`;
export const RATES_PATH = `${REVENU_MANAGEMENT_PATH}/rates`;
export const RATE_MANAGER_PATH = `${REVENU_MANAGEMENT_PATH}/rate-manager`;
export const END_OF_DAY_PATH = `${REVENU_MANAGEMENT_PATH}/end-of-day-check`;
export const FLASH_MANAGER_PATH = `${REVENU_MANAGEMENT_PATH}/flash-manager`;
export const PROMOS_PATH = `${REVENU_MANAGEMENT_PATH}/promos`;
/***************************************/
export const HOUSE_KEEPING_PATH = `${BASE_PATH}house-keeping`;
export const HOUSE_KEEPING_ROOM_STATUS_PATH = `${BASE_PATH}house-keeping/room_status`;
export const HOUSE_KEEPING_WORK_MANAGEMENT_PATH = `${BASE_PATH}house-keeping/work_management`;
export const MAINTENANCE_PATH = `${BASE_PATH}maintenance`;
export const FINANCIAL_PATH = `${BASE_PATH}financial`;
export const REPORT_PATH = `${BASE_PATH}report`;
export const SETTINGS_PATH = `${BASE_PATH}settings`;
/**************************************/
export const STAYCARD_PATH = `${STAYCARD_ROOT_PATH}/%s/%s/%s`;

/**
 * FINANCIAL AR ACCOUNTS
 */
export const FINANCIAL_ROOT_PATH = `${BASE_PATH}financial`;

export const AR_ACCOUNT_PAGE_PATH = `${FINANCIAL_ROOT_PATH}/ar-account`;
export const AR_ACCOUNT_CONFIRMATIONS_PAGE_PATH = `${AR_ACCOUNT_PAGE_PATH}/ar-account`;
export const AR_ACCOUNT_TRANSACTIONS_PAGE_PATH = `${AR_ACCOUNT_PAGE_PATH}/ar-account`;
