import { AppBar, Drawer, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { ReportMenu } from "./components/ReportMenu";

const ReportPage: FC = (): JSX.Element => {
  return (
    <Stack direction={"row"} sx={{ height: "100%", position: "relative" }}>
      <Drawer
        open={true}
        sx={{
          width: "18rem",
          flexShrink: 0,
          backgroundColor: "common.white",
          height: "100%",
          zIndex: 0,
          "& .MuiDrawer-paper": {
            position: "sticky",
            width: "18rem",
            boxSizing: "border-box",
            height: "100%",
            display: "flex",
            alignItems: "flex-end",
            border: "none",
          },
        }}
        variant="persistent"
      >
        <AppBar
          elevation={0}
          position="sticky"
          sx={{
            width: "17.5rem",
            backgroundColor: "colors.gray.o10",
            padding: "2rem 1rem 1.5rem 1.5rem",
          }}
        >
          <Typography
            variant="inter20"
            color="colors.blueR.o100"
            fontWeight={(theme) => theme.typography.fontWeightBold}
          >
            Report
          </Typography>
        </AppBar>
        <ReportMenu />
      </Drawer>
      <Stack sx={{ flexGrow: 1 }}>
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default ReportPage;
