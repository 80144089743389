import { CashRegisterReportRequestModel } from '../api/models/CashRegisterReportRequestModel';
import { CashRegisterReportFormModel } from '../models';

export const dataFormToRequest = (
  data: CashRegisterReportFormModel
): CashRegisterReportRequestModel => ({
  from: data.from,
  to: data.to,
  cash_register_id: data.cash_register_id,
  cashier_id: data.cashier_id,
});
