import { ACTIVE_ITEMS } from '@constants/activeItems';
import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { RootContext } from 'components/RootContainer';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const NationalityField: FC<{
  value: number[] | null;
  onChange: (value: number[] | null) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation('reservation');

  const {
    ChainApi: {
      settings: {
        nationality: { getAll },
      },
    },
  } = useContext(RootContext);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['get-nationalities'],
    queryFn: () => {
      return getAll({
        search: [ACTIVE_ITEMS],
      });
    },
  });

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const selectedValues = event.target.value;
    // If "all" is selected or empty selection, return null
    if (
      (Array.isArray(selectedValues) && selectedValues.includes('all' as unknown as number)) ||
      (Array.isArray(selectedValues) && selectedValues.length === 0)
    ) {
      onChange(null);
      return;
    }

    // Convert string values to numbers
    const numericValues = Array.isArray(selectedValues)
      ? selectedValues.map(val => Number(val))
      : [Number(selectedValues)];

    onChange(numericValues);
  };

  if (isLoading) {
    return <>...</>;
  }

  return (
    <FormControl variant="standard" fullWidth className="background">
      <InputLabel id="reservation-filter-nationalities">Nationalities</InputLabel>
      <Select
        labelId="reservation-filter-nationalities"
        multiple
        value={value || []}
        onChange={handleChange}
      >
        <MenuItem firstItem value="all">
          {t('list.filter.all')}
        </MenuItem>
        {(data || [])?.map((nationality, key) => (
          <MenuItem key={`nationality-key-${key}`} value={nationality.id!}>
            {nationality.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
