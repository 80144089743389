import { ErrorMessage } from '@hookform/error-message';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { Error } from 'components/Common/Error';
import { format, parse } from 'date-fns';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DATE_PICKER_FORMAT_MONTH } from '../../../../../../../../@constants/date';
import DatePicker from '../../../../../../../../components/DatePicker/DatePicker';
import { FilterEnum, ReservationListReportFormModel, SubFilterEnum } from '../../models';
import { CompanyField } from './fields/CompanyField';
import { CurrencyApplicationField } from './fields/currencyApplicationField';
import { CurrencyField } from './fields/CurrencyField';
import { FixedAtField } from './fields/FixedAtField';
import { RateField } from './fields/RateField';
import { RoomTypeField } from './fields/RoomTypeField';
import { StateField } from './fields/StateField';

export const FilterForm: FC<{
  onSubmit: (data: ReservationListReportFormModel) => void;
}> = ({ onSubmit }) => {
  const { control, handleSubmit, watch } = useFormContext<ReservationListReportFormModel>();

  console.log({ month: watch('month') });

  return (
    <Box sx={{ p: 0, height: '100%', backgroundColor: 'colors.gray.o20' }}>
      <Stack direction={'column'} spacing={0} sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ height: 80, backgroundColor: 'colors.gray.o10', p: 2 }}>
          <Stack direction={'row'} alignItems={'center'} sx={{ height: '100%' }}>
            <Typography
              align="center"
              color="secondary"
              variant="inter20"
              fontWeight={'fontWeightBold'}
            >
              Four Week Forecast
            </Typography>
          </Stack>
        </Box>
        <Stack direction={'row'} spacing={0}>
          {/******************************** LEFT BLOC ********************************/}

          <Box sx={{ width: '40%', p: 4, backgroundColor: 'colors.blue.o10' }}>
            <Stack direction={'column'} spacing={2}>
              <Box sx={{ height: 48, display: 'flex', alignItems: 'center', mt: 1 }}>
                <Controller
                  control={control}
                  name="with_revenue"
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ m: 0 }}
                      {...field}
                      checked={field.value ?? false}
                      onChange={(e, checked) => field.onChange(checked)}
                      label={
                        <Typography variant="inter16" fontWeight={500} sx={{ ml: '1rem' }}>
                          With Revenue
                        </Typography>
                      }
                      control={<Switch />}
                    />
                  )}
                />
              </Box>

              <Controller
                control={control}
                name="currency"
                render={({ field, formState }) => (
                  <CurrencyField
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    error={
                      formState?.errors[field.name] ? (
                        <ErrorMessage
                          errors={formState.errors}
                          name={field.name}
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      ) : null
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="currency_application"
                render={({ field, formState }) => (
                  <CurrencyApplicationField
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    error={
                      formState?.errors[field.name] ? (
                        <ErrorMessage
                          errors={formState.errors}
                          name={field.name}
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      ) : null
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="fixed_at"
                render={({ field, formState }) => (
                  <FixedAtField
                    value={field.value}
                    onChange={value => field.onChange(value)}
                    helperText={
                      <>
                        <ErrorMessage
                          errors={formState.errors}
                          name={field.name}
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </>
                    }
                  />
                )}
              />
            </Stack>
          </Box>
          {/******************************** RIGHT BLOC ********************************/}
          <Box sx={{ width: '60%', p: 3, backgroundColor: '#fff' }}>
            <Stack direction={'column'} spacing={2}>
              <Stack direction={'row'} spacing={2}>
                {/******************************** MONTH FIELD ********************************/}
                <Stack direction={'row'} spacing={2} width={'100%'}>
                  <Box sx={{ width: '50%' }}>
                    <Controller
                      control={control}
                      name="month"
                      render={({ field, formState }) => (
                        <DatePicker
                          value={
                            field.value
                              ? parse(field.value, DATE_PICKER_FORMAT_MONTH, new Date())
                              : null
                          }
                          onChange={(newValue: string) => {
                            if (!newValue) {
                              field.onChange(null);
                              return;
                            }
                            const dateObj = new Date(newValue);
                            const formattedValue = format(dateObj, DATE_PICKER_FORMAT_MONTH);
                            field.onChange(formattedValue);
                          }}
                          views={['year', 'month']}
                          openTo="month"
                          inputFormat={DATE_PICKER_FORMAT_MONTH}
                          renderInput={(params: TextFieldProps) => (
                            <TextField
                              id={`month-datepicker`}
                              {...params}
                              variant="standard"
                              className="background"
                              label="Month"
                              error={Boolean(formState.errors.month)}
                              helperText={formState.errors.month?.message}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Controller
                      control={control}
                      name="company"
                      render={({ field }) => (
                        <CompanyField
                          value={field.value}
                          onChange={value => field.onChange(value)}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Stack>

              <Stack direction={'row'} spacing={2}>
                <Controller
                  control={control}
                  name="rates"
                  render={({ field, formState }) => (
                    <RateField
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      error={
                        formState?.errors[field.name] ? (
                          <ErrorMessage
                            errors={formState.errors}
                            name={field.name}
                            render={({ message }) => <Error>{message}</Error>}
                          />
                        ) : null
                      }
                    />
                  )}
                />
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Box sx={{ width: '50%' }}>
                  <Controller
                    control={control}
                    name="roomType"
                    render={({ field }) => (
                      <RoomTypeField
                        value={field.value}
                        onChange={value => field.onChange(value)}
                      />
                    )}
                  />
                </Box>

                <Box sx={{ width: '50%' }}>
                  <Controller
                    control={control}
                    name="state_id"
                    render={({ field }) => (
                      <StateField value={field.value} onChange={value => field.onChange(value)} />
                    )}
                  />
                </Box>
              </Stack>

              <Stack direction="row" alignItems="flex-start" width={'100%'} spacing={10}>
                <FormControl sx={{ width: 200 }}>
                  <InputLabel sx={{ mt: 3 }}>Allocation / Garantee</InputLabel>
                  <Controller
                    control={control}
                    name="sub_filter"
                    render={({ field }) => (
                      <RadioGroup
                        sx={{ mt: 3 }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={field.value}
                        onChange={value => field.onChange(value)}
                      >
                        <FormControlLabel
                          value={SubFilterEnum.NONE}
                          control={<Radio />}
                          label="None"
                        />
                        <FormControlLabel
                          value={SubFilterEnum.ALLOCATION}
                          control={<Radio />}
                          label="Allocation"
                        />
                        <FormControlLabel
                          value={SubFilterEnum.GUARANTEE}
                          control={<Radio />}
                          label="Guarantee"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                <FormControl>
                  <InputLabel sx={{ mt: 3 }}>Type</InputLabel>
                  <Controller
                    control={control}
                    name="filter"
                    render={({ field }) => (
                      <RadioGroup
                        sx={{ mt: 3 }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={field.value}
                        onChange={value => field.onChange(value)}
                      >
                        <FormControlLabel
                          value={FilterEnum.ROOM}
                          control={<Radio />}
                          label="Room"
                        />
                        <FormControlLabel value={FilterEnum.PAX} control={<Radio />} label="Pax" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Stack>

              <Box sx={{ height: 48, display: 'flex', alignItems: 'center', mt: 1 }}>
                <Controller
                  control={control}
                  name="gross_revenue"
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ m: 0 }}
                      {...field}
                      checked={field.value ?? false}
                      onChange={(e, checked) => field.onChange(checked)}
                      label={
                        <Typography variant="inter16" fontWeight={500} sx={{ ml: '1rem' }}>
                          Gross Revenue
                        </Typography>
                      }
                      control={<Switch />}
                    />
                  )}
                />
              </Box>

              <Box sx={{ height: 10 }} />

              <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
                Exporter en PDF
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
