import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { LogsReportOperationEnum } from '../../../api/models/LogsReportRequestModel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ALL_VALUE = '-1';

export const OperationsField: FC<{
  value: LogsReportOperationEnum[] | null;
  onChange: (value: LogsReportOperationEnum[] | null) => void;
  error?: ReactNode;
}> = ({ value, onChange, error }) => {
  const { t } = useTranslation('common');

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    let selectedValues = event.target.value as string[];

    if (selectedValues.includes(ALL_VALUE)) {
      if (value === null) {
        onChange([]);
      } else {
        onChange(null);
      }
    } else {
      selectedValues = selectedValues.filter(val => val !== ALL_VALUE);
      onChange(
        selectedValues.length > 0 ? selectedValues.map(val => val as LogsReportOperationEnum) : []
      );
    }
  };

  const displayValue = value === null ? [ALL_VALUE] : value;

  return (
    <FormControl variant="standard" sx={{ width: '100%' }} className="background">
      <InputLabel id="operations-select-label">Operations</InputLabel>
      <Select
        error={Boolean(error)}
        labelId="operations-select-label"
        id="operations-select"
        multiple
        value={displayValue}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        <MenuItem key="all" value={ALL_VALUE}>
          All
        </MenuItem>
        {Object.values(LogsReportOperationEnum).map(item => (
          <MenuItem key={item} value={item}>
            {t(`report.operations.${item}`)}
          </MenuItem>
        ))}
      </Select>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
