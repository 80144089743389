import { CASHIER_BILLING } from '@constants/ApiEndpoints/CashierRootEndpoint';
import { AxiosInstance } from 'axios';
import CashierBillingModel from 'models/Cashier/CashierBilling';
import { CardProfileType } from 'models/RevenueManagement/Card/CardModel';

import { sprintf } from 'sprintf-js';
import { CASHIER_BILLING_PATH } from '../../../@constants/ApiEndpoints/CashierBillingRootEndpoints';

export type Params = {
  search?: string;
  limit?: number;
  offset?: number;
};

enum ArAccountStatusEnum {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
}

interface ArAccountModel {
  id: number;
  ar_account: number;
  company: {
    id: number;
    name: string;
    profile: CardProfileType;
  };
  status: ArAccountStatusEnum;
  balance: number;
}

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: {
    search?: string;
    type?: 'room' | 'account' | undefined;
    limit: number;
    offset: number;
  }): Promise<{
    items: CashierBillingModel[];
    total: number;
  }> =>
    await axios
      .get(`${CASHIER_BILLING_PATH}`, {
        params,
      })
      .then(data => {
        return {
          items: data?.data?.items || [],
          total: data?.data?.total || 0,
        };
      });

export const update =
  (axios: AxiosInstance) =>
  async (data: Partial<CashierBillingModel>): Promise<CashierBillingModel> => {
    return await axios.put(`${CASHIER_BILLING}/${data?.id}`, data).then(({ data }) => data?.items);
  };

export const create =
  (axios: AxiosInstance) =>
  async (data: Partial<CashierBillingModel>): Promise<CashierBillingModel> => {
    return await axios.post(sprintf(CASHIER_BILLING), data).then(({ data }) => data?.items);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashierBillingModel> => {
    return await axios.delete(`${CASHIER_BILLING}/${id}`);
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<CashierBillingModel> => {
    return await axios.patch(`${CASHIER_BILLING}/${id}`).then(({ data }) => data);
  };
