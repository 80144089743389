import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DATE_FORMAT_API } from '../../../../../../../../@constants/date';
import DatePicker from '../../../../../../../../components/DatePicker/DatePicker';
import { DailyReportFormModel } from '../../models';

export const FilterForm: FC<{
  onSubmit: (data: DailyReportFormModel) => void;
}> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<DailyReportFormModel>();

  const formValues = watch();

  return (
    <Box sx={{ p: 0, height: '100%', backgroundColor: 'colors.gray.o20' }}>
      <Stack direction={'column'} spacing={0} sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ height: 80, backgroundColor: 'colors.gray.o10', p: 2 }}>
          <Stack direction={'row'} alignItems={'center'} sx={{ height: '100%' }}>
            <Typography
              align="center"
              color="secondary"
              variant="inter20"
              fontWeight={'fontWeightBold'}
            >
              Daily Report
            </Typography>
          </Stack>
        </Box>
        <Stack direction={'row'} spacing={0}>
          <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#fff', pt: 5 }}>
            <Stack direction={'column'} spacing={2}>
              <DatePicker
                name="day"
                label="Day"
                value={formValues?.day}
                onChange={(value: string) =>
                  setValue('day', format(new Date(value), DATE_FORMAT_API))
                }
                error={Boolean(errors?.day)}
                className="background"
                helperText={errors?.day?.message}
                renderInput={(params: any) => (
                  <TextField variant="standard" className="background" {...params} />
                )}
              />
              <Stack direction={'row'} spacing={2}>
                <Box width={'100%'}>
                  <Controller
                    control={control}
                    name="comment"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Comment"
                        multiline
                        rows={4}
                        fullWidth
                        variant="standard"
                        className="background"
                        error={Boolean(errors?.comment)}
                        helperText={errors?.comment?.message}
                      />
                    )}
                  />
                </Box>
              </Stack>
              <Box sx={{ height: 10 }} />

              <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
                Exporter en PDF
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
