import { AxiosInstance } from 'axios';
import * as FinancialAccountDetailsService from './services';

export interface FinancialAccountDetailsServiceType {
  getData: ReturnType<typeof FinancialAccountDetailsService.getData>;
  //   getBills: ReturnType<typeof FinancialReservationService.getBills>;
  addNewBill: ReturnType<typeof FinancialAccountDetailsService.addNewBill>;
  deleteBill: ReturnType<typeof FinancialAccountDetailsService.deleteBill>;
  postTransaction: ReturnType<typeof FinancialAccountDetailsService.postTransaction>;
  editCharge: ReturnType<typeof FinancialAccountDetailsService.editCharge>;
  //   editPayment: ReturnType<typeof FinancialReservationService.editPayment>;
  postPayment: ReturnType<typeof FinancialAccountDetailsService.postPayment>;
  transferCharge: ReturnType<typeof FinancialAccountDetailsService.transferCharge>;
  transferPayment: ReturnType<typeof FinancialAccountDetailsService.transferPayment>;
  transferTransactionsToCompany: ReturnType<
    typeof FinancialAccountDetailsService.transferTransactionsToCompany
  >;
  billSearch: ReturnType<typeof FinancialAccountDetailsService.billSearch>;
}

export const initFinancialAccountDetailsService = (
  axios: AxiosInstance
): FinancialAccountDetailsServiceType => ({
  getData: FinancialAccountDetailsService.getData(axios),
  //   getBills: FinancialReservationService.getBills(axios),
  addNewBill: FinancialAccountDetailsService.addNewBill(axios),
  deleteBill: FinancialAccountDetailsService.deleteBill(axios),
  postTransaction: FinancialAccountDetailsService.postTransaction(axios),
  editCharge: FinancialAccountDetailsService.editCharge(axios),
  //   editPayment: FinancialReservationService.editPayment(axios),
  transferCharge: FinancialAccountDetailsService.transferCharge(axios),
  transferPayment: FinancialAccountDetailsService.transferPayment(axios),
  transferTransactionsToCompany:
    FinancialAccountDetailsService.transferTransactionsToCompany(axios),
  billSearch: FinancialAccountDetailsService.billSearch(axios),
  postPayment: FinancialAccountDetailsService.postPayment(axios),
});
