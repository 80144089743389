import {
  AR_ACCOUNT_CONFIRM_INVOICE_PATH,
  AR_ACCOUNT_CONFIRMATIONS_INVOICED_PATH,
  AR_ACCOUNT_CONFIRMATIONS_INVOICED_PRINT_PATH,
  AR_ACCOUNT_CONFIRMATIONS_PATH,
  AR_ACCOUNT_INVOICE_PREVIEW_PATH,
  AR_ACCOUNT_PATH,
  AR_ACCOUNT_TRANSACTIONS_PATH,
} from '@constants/ApiEndpoints/FinancialRootEndpoints';
import { AxiosInstance } from 'axios';
import _ from 'lodash';
import { ArTransactionItemModel } from 'models/Financial/ArTransactionItemModel';
import { CardModel, CardProfileType } from 'models/RevenueManagement/Card/CardModel';
import { CurrencyModel } from 'models/Settings/Financial/CurrencyModel';
import { SortRequestModels } from 'models/Shared/FetchingRequestParamsModels';
import { ArAccountConfirmatioStatusFilterEnum } from 'pages/FinancialPage/AccountConfirmationsPage/models/ArAccountConfirmationFilterPropsModel';
import { ArAccountModel } from 'pages/FinancialPage/ArAccountPage/models/ArAccountModel';
import { sprintf } from 'sprintf-js';

export type ArAccountFilterFormModel = {
  search?: string;
  limit?: number;
  offset?: number;
  agencies?: number[];
  markets?: number[];
  with_balance?: boolean;
  balance_range?: [number, number];
  sort?: SortRequestModels;
};

export enum InvoiceStatusEnum {
  INVOICED = 'invoiced',
  PARTIALLY_INVOICED = 'partially_invoiced',
  NOT_INVOICED = 'not_invoiced',
}

export interface ArAccountTransactionModel {
  id: number;
  bill_id: number;
  guest_card: {
    id: number;
    first_name: string;
    last_name: string;
  };
  occupancy: {
    adults: number;
    children: number;
    babies: number;
  };
  crs_number: string;
  rate_room_type: {
    id: number;
    name: string;
  };
  room_type: {
    id: number;
    code: string;
  };
  room: {
    id: number;
    number: string;
    room_type_id: number;
    room_type_code: string;
  };
  board: {
    id: number;
    name: string;
  };
  rate: {
    id: number;
    name: string;
    code: string;
  };
  state: {
    id: number;
    name: string;
  };
  booking_date: string;
  arrival: string;
  departure: string;
  voucher: string;
  confirmation_number: string;
  type: 'room' | 'account';
  local_balance: string;
  balance: string;
  local_total: string;
  total: string;
  currency: CurrencyModel;
  status_invoice: InvoiceStatusEnum;
  invoice_number?: string;
}

export interface ArAccountInvoiceItemModel {
  currency?: CurrencyModel;
  date: string;
  gross_amount: string;
  id: number | string;
  local_gross_amount: string;
  local_net_amount: string;
  local_taxes_amount: number;
  number: string;
  state: ArAccountInvoiceState;
  type: ArAccountInvoiceTypeEnum;
}

export enum ArAccountInvoiceTypeEnum {
  DEBIT_INVOICE = 'debit_invoice',
  CREDIT_INVOICE = 'credit_invoice',
  INDIVIDUAL_INVOICE = 'individual_invoice',
  PROFORMA_INVOICE = 'proforma_invoice',
}

export enum ArAccountInvoiceState {
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  PAID = 'payed',
}

export interface ArAccountTransactionResponse {
  total: number;
  items: ArAccountTransactionModel[];
  agency_card: CardModel['general'] & {
    country: {
      id: number;
      name: string;
    };
  };
}

export const getAll =
  (axios: AxiosInstance) =>
  async (
    params?: ArAccountFilterFormModel
  ): Promise<{
    items: ArAccountModel[];
    total: number;
  }> =>
    await axios
      .get(`${AR_ACCOUNT_PATH}`, {
        params,
      })
      .then(({ data }) => data);

interface ArAccountConfirmationsParams {
  id: number;
  search: string;
  type: string;
  from?: string | null;
  date_type?: string | null;
  to?: string | null;
  status?: ArAccountConfirmatioStatusFilterEnum;
  limit: number;
  offset: number;
}

export const getConfirmationsByArAccount =
  (axios: AxiosInstance) =>
  async (
    params?: Partial<ArAccountConfirmationsParams>
  ): Promise<{
    total: number;
    items: ArAccountTransactionModel[];
    agency_card: CardModel['general'] & {
      country: {
        id: number;
        name: string;
      };
    };
  }> => {
    return await axios
      .get(sprintf(AR_ACCOUNT_CONFIRMATIONS_PATH, params?.id!), {
        params,
      })
      .then(({ data }) => data);
  };

export const getConfirmationsByArAccountInvoiced =
  (axios: AxiosInstance) =>
  async (
    params?: Partial<ArAccountConfirmationsParams>
  ): Promise<{
    total: number;
    items: ArAccountInvoiceItemModel[];
    agency_card: CardModel['general'] & {
      country: {
        id: number;
        name: string;
      };
    };
  }> => {
    return await axios
      .get(sprintf(AR_ACCOUNT_CONFIRMATIONS_INVOICED_PATH, params?.id!), {
        params,
      })
      .then(({ data }) => data);
  };

export const exportConfirmationsByArAccountInvoiced =
  (axios: AxiosInstance) =>
  async (
    params?: Partial<ArAccountConfirmationsParams> & {
      ids: string[];
      useLocalMoney?: boolean;
    }
  ): Promise<void> => {
    return await axios
      .get(sprintf(AR_ACCOUNT_CONFIRMATIONS_INVOICED_PRINT_PATH, params?.id!), {
        params: {
          timestamp: new Date().getTime(),
          ids: params?.ids,
          use_local_money: params?.useLocalMoney,
        },
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
      })
      .then(response => {
        const file = new Blob([response as any], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      });
  };

export type ArAccountConfirmationSelectedTransaction = {
  id: string;
  type: 'room' | 'account';
  childrenIds?: string[];
  total: string;
  totalLocal: string;
  allValidChildrenChecked?: boolean;
};

interface InvoiceItemModel {
  reference: string;
  service: string;
  description: string;
  amount: number;
  local_amount: number;
}

export interface MakeInvoiceModel {
  main: {
    company: {
      name: string;
      id: number;
      profile_type?: CardProfileType;
    };
    linked_companies: Array<{ name: string; id: number; profile_type?: CardProfileType }>;
    currency: CurrencyModel;
    gross_amount: number;
    local_gross_amount: number;
    net_amount: number;
    local_net_amount: number;
    taxes: Array<{
      name: string;
      amount: number;
      local_amount: number;
      rate: string;
    }>;
  };
  details: {
    accounts: Array<InvoiceItemModel>;
    rooms: Array<InvoiceItemModel>;
  };
}

export const makeInvoice =
  (axios: AxiosInstance) =>
  async (params: {
    id: number;
    selected_items: Omit<ArAccountConfirmationSelectedTransaction, 'total' | 'totalLocal'>[];
  }): Promise<MakeInvoiceModel> => {
    return await axios
      .post(sprintf(AR_ACCOUNT_INVOICE_PREVIEW_PATH, params?.id), _.omit(params, 'id'))
      .then(({ data }) => data);
  };

export const validateInvoice =
  (axios: AxiosInstance) =>
  async (params?: {
    id: number;
    selected_items: { id: string; type: 'room' | 'account'; childrenIds?: string[] }[];
    agency_card_id: number;
    comments: string;
  }): Promise<{
    total: number;
    items: ArAccountTransactionModel[];
    agency_card: CardModel['general'] & {
      country: {
        id: number;
        name: string;
      };
    };
  }> => {
    return await axios
      .post(sprintf(AR_ACCOUNT_CONFIRM_INVOICE_PATH, params?.id), _.omit(params, 'id'))
      .then(({ data }) => data);
  };

export const getTransactionsByArAccountConfirmation =
  (axios: AxiosInstance) =>
  async (params?: {
    id: number;
    type: 'room' | 'account';
    confirmation_id: number;
  }): Promise<ArTransactionItemModel[]> => {
    return await axios
      .get(
        sprintf(AR_ACCOUNT_TRANSACTIONS_PATH, params?.id!, params?.type, params?.confirmation_id)
      )
      .then(({ data }) => data);
  };
