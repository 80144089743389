import { UseFormReturn } from 'react-hook-form';
import { CurrencyApplicationEnum, ReservationListReportFormModel } from '../models';
import { useEffect } from 'react';

export const useDependenciesValues = (
  form: UseFormReturn<ReservationListReportFormModel, any, undefined>
) => {
  /*******************************  dependencies values *******************************/
  const withRevenueValue = form.watch('with_revenue');
  const currencyValue = form.watch('currency');
  const currencyApplicationValue = form.watch('currency_application');
  return useEffect(() => {
    if (withRevenueValue === false) {
      form.setValue('currency', null);
      form.setValue('fixed_at', null);
      form.setValue('currency_application', null);
    }
    if (currencyValue?.is_local === true) {
      form.setValue('fixed_at', null);
      form.setValue('currency_application', null);
    }

    if (currencyApplicationValue === CurrencyApplicationEnum.DAILY) {
      form.setValue('fixed_at', null);
    }

    if (form.formState.isSubmitted) {
      form.trigger();
    }
  }, [withRevenueValue, currencyValue, currencyApplicationValue]);
};
