import { Backdrop, CircularProgress } from "@mui/material";
import { FC } from "react";

export const BackdropLoading: FC<{ isLoading: Boolean }> = ({
  isLoading,
}): JSX.Element => {
  return (
    <Backdrop
      sx={{
        color: "colors.gray.o70",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: "absolute",
        backgroundColor: "transparent",
        backdropFilter: "blur(1px)",
      }}
      open={Boolean(isLoading)}
    >
      <CircularProgress size={20} color="inherit" />
    </Backdrop>
  );
};
