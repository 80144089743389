import { GlobalSettingsModel } from 'models/Shared/GlobalSettingsModel';
import { ReservationListReportFormModel, SubFilterEnum } from '../../models';

export const getDefaultValues = (
  globalSettings: GlobalSettingsModel
): ReservationListReportFormModel => ({
  from: globalSettings?.frontend_date,
  to: null,
  company: null,
  rates: [],
  roomType: null,
  with_revenue: false,
  currency: null,
  currency_application: null,
  fixed_at: '',
  state_id: null,
  sub_filter: SubFilterEnum.NONE,
});
