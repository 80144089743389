import { RootContextProps } from 'components/RootContainer';
import i18next from 'i18next';
import React, { FC } from 'react';
import { RouteObject, useRouteError } from 'react-router-dom';
import { Breadcrumb } from 'routing/components/Breadcrumb';

const AccessibilyPage = React.lazy(() => import('../Pages/AccessibilityPage'));
const LandingPage = React.lazy(() => import('../Pages/LandingPage'));
const LanguagesPage = React.lazy(() => import('../Pages/LanguagesPage'));
const SecurityPage = React.lazy(() => import('../Pages/SecurityPage'));
const EndOfDatePage = React.lazy(() => import('../Pages/EndOfDatePage'));

const AclPage = React.lazy(() => import('../Pages/AclPage'));

const SettingsCardsBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message}</p>
    </>
  );
};

export const routePathsApplicationSettings = (api: RootContextProps): RouteObject[] => [
  {
    index: true,
    element: <LandingPage />,
    errorElement: <SettingsCardsBoundary />,
  },
  {
    path: 'languages',
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t('settings:ApplicationSettingsConfig.landingPage.languages')}
          path={'settings/application-settings/languages'}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <LanguagesPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: 'security',
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t('settings:ApplicationSettingsConfig.landingPage.security')}
          path={'settings/application-settings/security'}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <SecurityPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    path: 'interfaces-accessibility',
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            'settings:ApplicationSettingsConfig.landingPage.interfaces-and-accessibility'
          )}
          path={'settings/application-settings/interfaces-accessibility'}
        />
      ),
    },
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<p>...LoadingPage</p>}>
            <AccessibilyPage />
          </React.Suspense>
        ),
      },
    ],
  },

  {
    path: 'acl',
    element: <AclPage />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t(
            'settings:ApplicationSettingsConfig.landingPage.interfaces-and-accessibility'
          )}
          path={'settings/application-settings/interfaces-accessibility'}
        />
      ),
    },
  },

  {
    path: 'end-of-day',
    element: (
      <React.Suspense fallback={<>...</>}>
        <EndOfDatePage />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Breadcrumb label="End Of Day" path={null} />,
    },
  },
];
