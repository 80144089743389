import { RatesEndpoint } from '@constants/ApiEndpoints';
import { AxiosInstance, AxiosResponse } from 'axios';
import {
  AssignToSearchInEnum,
  AssignToSearchResponseModel,
} from 'models/RevenueManagement/Card/CardModel';
import {
  RateByMarketAndCard,
  RateByRoomType,
  RateCreateModel,
  RateList,
  RateModel,
} from 'models/RevenueManagement/Rate/RateModel';
import { sprintf } from 'sprintf-js';

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: any): Promise<RateList> => {
    return await axios.get(RatesEndpoint.RATE_GET_ALL, { params }).then(({ data }) => data.items);
  };

export const getByRoomType =
  (axios: AxiosInstance) =>
  async (params: {
    reservationId: string | number | null;
    roomTypeId: string | number | null;
    start_date: string;
    end_date: string;
  }): Promise<RateByRoomType[]> => {
    return await axios
      .post(sprintf(RatesEndpoint.RATE_GET_BY_ROOM_TYPE, params.reservationId, params.roomTypeId), {
        start_date: params.start_date,
        end_date: params.end_date,
      })
      .then(({ data }) => data.items);
  };

export const getByMarketAndCard =
  (axios: AxiosInstance) =>
  async (params: {
    start_date: string;
    end_date: string | null;
    market_code_id: string | number | null;
    card_id: string | number | null | undefined;
  }): Promise<RateByMarketAndCard[]> => {
    return await axios
      .get(RatesEndpoint.RATE_GET_BY_MARKET_AND_CARD, { params })
      .then(({ data }) => data);
  };

export const getOneById =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RateModel> => {
    return await axios.get(sprintf(RatesEndpoint.RATE_GET_ONE, id)).then(({ data }) => data);
  };

export const create =
  (axios: AxiosInstance) =>
  async (rate: RateCreateModel): Promise<AxiosResponse<RateCreateModel>> => {
    return await axios.post(RatesEndpoint.RATE_CREATE, rate);
  };

export const update =
  (axios: AxiosInstance) =>
  async (rate: { details: Partial<RateModel['details']> }): Promise<RateModel> => {
    return await axios
      .put(sprintf(RatesEndpoint.RATE_UPDATE, rate.details.id), rate)
      .then(({ data }) => data);
  };

export const updateAsDraft =
  (axios: AxiosInstance) =>
  async (rate: { details: Partial<RateModel['details']> }): Promise<RateModel['details']> => {
    return await axios
      .put(sprintf(RatesEndpoint.RATE_UPDATE_AS_DRAFT, rate.details.id), rate)
      .then(({ data }) => data);
  };

export const remove =
  (axios: AxiosInstance) =>
  async (rate: { details: Partial<RateModel['details']> }): Promise<AxiosResponse<RateModel>> => {
    return await axios.put(sprintf(RatesEndpoint.RATE_DELETE, rate.details.id), rate);
  };

export const discard =
  (axios: AxiosInstance) =>
  async (rateId: number): Promise<AxiosResponse<RateModel>> => {
    return await axios.put(sprintf(RatesEndpoint.RATE_DISCARD, rateId));
  };

export const toggleStatus =
  (axios: AxiosInstance) =>
  async (id: number): Promise<RateModel> => {
    return await axios
      .patch(sprintf(RatesEndpoint.RATE_TOGGLE_STATUS, id))
      .then(({ data }) => data);
  };
export const searchForAssignment =
  (axios: AxiosInstance) =>
  async (params?: {
    limit?: number;
    offset?: number;
    search?: string;
    type?: AssignToSearchInEnum | '';
  }): Promise<{ items: AssignToSearchResponseModel[]; total: number }> => {
    return await axios
      .get(RatesEndpoint.RATE_ASSIGN_MARKET_GROUP_AGENCY, {
        params,
      })
      .then(({ data }) => data);
  };
