import { HotelIcon } from 'components/SvgIcons/HotelIcon';
import { ReportMenuModel } from 'pages/ReportModule/components/ReportMenuItem';
import {
  REPORT_CASHIER_CASH_REGISTER_PATH,
  REPORT_CASHIER_PATH,
  REPORT_FINANCIAL_PATH,
  REPORT_FRONTDESK_ARRIVAL_PATH,
  REPORT_FRONTDESK_DAILY_REPORT_PATH,
  REPORT_FRONTDESK_DEPARTURE_PATH,
  REPORT_FRONTDESK_INHOUSE_PATH,
  REPORT_FRONTDESK_LOGS_REPORT_PATH,
  REPORT_FRONTDESK_PATH,
  REPORT_HOUSEKEEPING_PATH,
  REPORT_NIGHT_AUDIT_PATH,
  REPORT_RESERVATION_DAILY_FORECAST_PATH,
  REPORT_RESERVATION_FORECAST_BY_ROOM_TYPE_PATH,
  REPORT_RESERVATION_FOUR_WEEK_FORECAST_PATH,
  REPORT_RESERVATION_PATH,
  REPORT_RESERVATION_RESERVATION_LIST_PATH,
} from './AppPaths/ReportPaths';

export const reportMenuItems: Array<ReportMenuModel> = [
  {
    icon: <HotelIcon />,
    name: 'report.menu.reservation',
    path: REPORT_RESERVATION_PATH,
    children: [
      {
        icon: <HotelIcon />,
        name: 'report.menu.daily_forecast',
        path: REPORT_RESERVATION_DAILY_FORECAST_PATH,
      },
      {
        icon: <HotelIcon />,
        name: 'report.menu.forecast_by_room_type',
        path: REPORT_RESERVATION_FORECAST_BY_ROOM_TYPE_PATH,
      },
      {
        icon: <HotelIcon />,
        name: 'report.menu.four_week_forecast',
        path: REPORT_RESERVATION_FOUR_WEEK_FORECAST_PATH,
      },
      {
        icon: <HotelIcon />,
        name: 'report.menu.reservation_list',
        path: REPORT_RESERVATION_RESERVATION_LIST_PATH,
      },
    ],
  },
  {
    icon: <HotelIcon />,
    name: 'report.menu.frontdesk',
    path: REPORT_FRONTDESK_PATH,
    children: [
      {
        icon: <HotelIcon />,
        name: 'report.menu.arrival',
        path: REPORT_FRONTDESK_ARRIVAL_PATH,
      },
      {
        icon: <HotelIcon />,
        name: 'report.menu.departure',
        path: REPORT_FRONTDESK_DEPARTURE_PATH,
      },
      {
        icon: <HotelIcon />,
        name: 'report.menu.inhouse',
        path: REPORT_FRONTDESK_INHOUSE_PATH,
      },
      {
        icon: <HotelIcon />,
        name: 'report.menu.daily_report',
        path: REPORT_FRONTDESK_DAILY_REPORT_PATH,
      },
      {
        icon: <HotelIcon />,
        name: 'report.menu.logs',
        path: REPORT_FRONTDESK_LOGS_REPORT_PATH,
      },
    ],
  },
  {
    icon: <HotelIcon />,
    name: 'report.menu.cashier',
    path: REPORT_CASHIER_PATH,
    children: [
      {
        icon: <HotelIcon />,
        name: 'report.menu.cash_register',
        path: REPORT_CASHIER_CASH_REGISTER_PATH,
      },
    ],
  },
  {
    icon: <HotelIcon />,
    name: 'report.menu.housekeeping',
    path: REPORT_HOUSEKEEPING_PATH,
    children: [
      {
        icon: <HotelIcon />,
        name: 'report.menu.comming_soon',
        path: '',
      },
    ],
  },
  {
    icon: <HotelIcon />,
    name: 'report.menu.financial',
    path: REPORT_FINANCIAL_PATH,
    children: [
      {
        icon: <HotelIcon />,
        name: 'report.menu.comming_soon',
        path: '',
      },
    ],
  },
  {
    icon: <HotelIcon />,
    name: 'report.menu.night_audit',
    path: REPORT_NIGHT_AUDIT_PATH,
    children: [
      {
        icon: <HotelIcon />,
        name: 'report.menu.comming_soon',
        path: '',
      },
    ],
  },
];
