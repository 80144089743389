import axios from 'axios';
import { AppRunningModeModel } from 'models/Shared/AppRunningModeModel';

export const createApiInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    withCredentials: true,
    ...(process.env.REACT_APP_MODE !== AppRunningModeModel.STAGING
      ? {
          params: {
            fake_email: process.env.REACT_APP_API_USER_NAME,
          },
        }
      : {}),
  });

  instance.interceptors.response.use(
    response => {
      return response.data;
    },
    error => {
      const needAuth = error?.response?.status === 302;
      const isUnauthorizedError = error?.response !== undefined && error.response.status === 401;

      //ERR_NETWORK
      if (needAuth || isUnauthorizedError) {
        window.location.href = '/';
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
