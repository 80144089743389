import { AxiosInstance } from 'axios';
import { DiscountTypeServiceModel, initBillingTypeService } from './billing/BillingService';
import {
  BillingGroupServiceModel,
  initBillingGroupService,
} from './BillingGroup/BillingGroupService';
import { ChargeCodeServiceModel, initChargeCodeService } from './ChargeCode/ChargeCodeService';
import { ChargeGroupServiceModel, initChargeGroupService } from './ChargeGroup/ChargeGroupService';
import { SettingsCurrencyServiceModel, initCurrencyService } from './Currency/CurrencyService';
import { ItemServiceModel, initItemService } from './Item/ItemService';
import {
  initInvoiceChargeGroupService,
  InvoiceChargeGroupServiceModel,
} from './InvoiceChargeGroup/InvoiceChargeGroupService';

export interface SettingRevenuManagementServiceModel {
  chargeCode: ChargeCodeServiceModel;
  chargeGroup: ChargeGroupServiceModel;
  invoiceChargeGroup: InvoiceChargeGroupServiceModel;
  billingGroup: BillingGroupServiceModel;
  item: ItemServiceModel;
  discountType: DiscountTypeServiceModel;
  currency: SettingsCurrencyServiceModel;
}

export const initSettingRevenuManagementService = (
  axios: AxiosInstance
): SettingRevenuManagementServiceModel => ({
  chargeCode: initChargeCodeService(axios),
  chargeGroup: initChargeGroupService(axios),
  invoiceChargeGroup: initInvoiceChargeGroupService(axios),
  item: initItemService(axios),
  currency: initCurrencyService(axios),
  billingGroup: initBillingGroupService(axios),
  discountType: initBillingTypeService(axios),
});
