import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FC } from "react";
import { DateTypeEnum } from "../../../models";

export const DateTypeField: FC<{
    value: DateTypeEnum | null;
    onChange: (value: DateTypeEnum) => void;
}> = ({ value, onChange }) => {
    return <FormControl>
        <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange((event.target as HTMLInputElement).value as DateTypeEnum);
            }}
        >
            <FormControlLabel value={DateTypeEnum.BOOKING_DATE} control={<Radio />} label="Booking date" />
            <FormControlLabel value={DateTypeEnum.INSERT_DATE} control={<Radio />} label="Insert date" />
        </RadioGroup>
    </FormControl>
}
