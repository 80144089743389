import { DATE_PICKER_FORMAT } from "@constants/date";
import {
  DatePickerProps,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers";
import { Calendar2Icon } from "components/SvgIcons/Calendar2Icon";
import { isValid } from "date-fns";
import React from "react";
import { inputDateValue, outputDateValue } from "utils/InputOutputDateFormat";

const OpenPickerIcon = () => <Calendar2Icon fontSize="small" />;

const DatePicker = React.forwardRef<DatePickerProps<Date, Date>, any>(
  (props, ref) => {
    const isValidDate = (date: Date) => {
      if (!isValid(date)) return false;
      const year = date.getFullYear();
      return year >= 1900 && year <= 2100; // Limiter entre 1900 et 2100
    };

    return (
      <MuiDatePicker
        ref={ref}
        inputFormat={DATE_PICKER_FORMAT}
        components={{ OpenPickerIcon }}
        OpenPickerButtonProps={{
          sx: {
            color: "common.black",
          },
        }}
        {...props}
        value={props.value ? inputDateValue(props.value) : null}
        onChange={(value: Date) => {
          if (isValidDate(value)) {
            props.onChange?.(outputDateValue(value));
          }
        }}
      />
    );
  }
);

export default DatePicker;
