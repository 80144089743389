import { AxiosInstance } from 'axios';
import { FinancialArAccount } from 'services';

export interface FinancialServiceServiceModel {
  getAll: ReturnType<typeof FinancialArAccount.getAll>;
  getConfirmationsByArAccount: ReturnType<typeof FinancialArAccount.getConfirmationsByArAccount>;
  getConfirmationsByArAccountInvoiced: ReturnType<
    typeof FinancialArAccount.getConfirmationsByArAccountInvoiced
  >;
  getTransactionsByArAccountConfirmation: ReturnType<
    typeof FinancialArAccount.getTransactionsByArAccountConfirmation
  >;
  validateInvoice: ReturnType<typeof FinancialArAccount.validateInvoice>;
  makeInvoice: ReturnType<typeof FinancialArAccount.makeInvoice>;
  exportConfirmationsByArAccountInvoiced: ReturnType<
    typeof FinancialArAccount.exportConfirmationsByArAccountInvoiced
  >;
}

export const initFinancialServiceService = (
  axios: AxiosInstance
): FinancialServiceServiceModel => ({
  getAll: FinancialArAccount.getAll(axios),
  getConfirmationsByArAccount: FinancialArAccount.getConfirmationsByArAccount(axios),
  getTransactionsByArAccountConfirmation:
    FinancialArAccount.getTransactionsByArAccountConfirmation(axios),
  validateInvoice: FinancialArAccount.validateInvoice(axios),
  makeInvoice: FinancialArAccount.makeInvoice(axios),
  getConfirmationsByArAccountInvoiced:
    FinancialArAccount.getConfirmationsByArAccountInvoiced(axios),
  exportConfirmationsByArAccountInvoiced:
    FinancialArAccount.exportConfirmationsByArAccountInvoiced(axios),
});
