import { CurrencyModel } from 'models/Settings/Financial/CurrencyModel';

export function intlNumberFormat(
  locale: string | undefined,
  currency: string | undefined,
  value: number
) {
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(value);
  } catch (e) {
    return value;
  }
}

export function currencyFormat(
  locale: string | undefined,
  currency: Partial<CurrencyModel> | undefined,
  value: number | string,
  withoutCurrencySymbol?: boolean
) {
  try {
    return Boolean(locale && currency)
      ? `${String(
          intlNumberFormatWithoutSymbol(
            locale!,
            currency!.code!,
            value,
            currency?.decimal_places ?? 3,
            currency?.decimal_places ?? 3
          )
        ).replace(/,/g, currency?.thousand_separator ?? ' ')} ${
          withoutCurrencySymbol ? '' : getCurrencySymbol(locale!, currency!.code!)
        }`
      : value;
  } catch (e) {
    return value;
  }
}

export const getCurrencySymbol = (locale: string, currency: string) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

export function intlNumberFormatWithoutSymbol(
  locale: string,
  currency: string,
  value: number | string,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
) {
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: minimumFractionDigits ?? 3,
      maximumFractionDigits: maximumFractionDigits ?? 3,
    })
      .format(+value)
      .replace(getCurrencySymbol(locale, currency), '')
      .trim();
  } catch (e) {
    return value;
  }
}
