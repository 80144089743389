import { Button, styled } from "@mui/material";

export const ReportButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  borderRadius: 8,
  border: "solid 1px transparent",
  backgroundColor: isSelected
    ? theme.palette.colors.purple.o10
    : theme.palette.common.white,
  boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.1)",
  color: isSelected
    ? theme.palette.colors.purple.o70
    : theme.palette.common.black,
  fontSize: theme.typography.inter16.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  "&:hover": {
    border: "solid 1px #7e5bef",
    backgroundColor: theme.palette.colors.purple.o10,
    color: theme.palette.colors.purple.o70,
    "& .MuiSvgIcon-root": {
      color: theme.palette.colors.purple.o70,
    },
  },
  "&.active": {
    border: "solid 1px #7e5bef",
    backgroundColor: theme.palette.colors.purple.o10,
    color: theme.palette.colors.purple.o70,
    "& .MuiSvgIcon-root": {
      color: theme.palette.colors.purple.o70,
    },
  },
  "& .MuiSvgIcon-root": {
    color: isSelected
      ? theme.palette.colors.purple.o70
      : theme.palette.colors.gray.o70,
  },
  ...(isSelected && {
    border: "solid 1px #7e5bef",
  }),
}));
