import { GlobalSettingsModel } from 'models/Shared/GlobalSettingsModel';
import { DateTypeEnum } from '../../models';

export const getDefaultValues = (globalSettings: GlobalSettingsModel) => ({
  from: globalSettings?.frontend_date,
  to: null,
  company: null,
  rates: [],
  roomType: null,
  with_revenue: false,
  currency: null,
  currency_application: null,
  fixed_at: '',
  date_type: DateTypeEnum.BOOKING_DATE,
  state_id: null,
  gross_revenue: false,
});
