import { SettingsEndpoint } from '@constants/ApiEndpoints';
import { AxiosInstance } from 'axios';
import { CounterSettingsApiModel } from 'models/Settings/Financial/CounterLinkModel';
import { FetchingRequestParamsModels } from 'models/Shared/FetchingRequestParamsModels';

export const getAll =
  (axios: AxiosInstance) =>
  async (params?: FetchingRequestParamsModels): Promise<CounterSettingsApiModel[]> => {
    return await axios
      .get(SettingsEndpoint.SETTINGS_APPLICATION_COUNTER_LINK, {
        params,
      })
      .then(({ data }) => data.items);
  };

export const update =
  (axios: AxiosInstance) =>
  async (data: { counterLinkId: number; counterId: number }): Promise<CounterSettingsApiModel> => {
    return await axios
      .patch(`/${SettingsEndpoint.SETTINGS_APPLICATION_COUNTER_LINK}/${data.counterLinkId}`, {
        counter_id: data?.counterId,
      })
      .then(({ data }) => data);
  };

export const attachTax =
  (axios: AxiosInstance) =>
  async (data: {
    counterSettingId: number;
    chargeCodeId: number;
  }): Promise<CounterSettingsApiModel> => {
    return await axios
      .post(`/${SettingsEndpoint.SETTINGS_APPLICATION_COUNTER_LINK}/${data?.counterSettingId}/attach`, {
        charge_code_id: data?.chargeCodeId,
      })
      .then(({ data }) => data);
  };
  export const detachTax =
  (axios: AxiosInstance) =>
  async (data: {
    counterSettingId: number;
    chargeCodeId: number;
  }): Promise<CounterSettingsApiModel> => {
    return await axios
      .delete(`/${SettingsEndpoint.SETTINGS_APPLICATION_COUNTER_LINK}/${data?.counterSettingId}/detach/${data?.chargeCodeId}`)
      .then(({ data }) => data);
  };
