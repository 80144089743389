import { REPORT_CASHIER_CASH_REGISTER_PATH } from '@constants/Navigation/AppPaths/ReportPaths';
import { Box } from '@mui/material';
import { RootContextProps } from 'components/RootContainer';
import i18next from 'i18next';
import React, { FC } from 'react';
import { RouteObject, useRouteError } from 'react-router-dom';
import { Breadcrumb } from 'routing/components/Breadcrumb';
import LoadingPage from '../../../../../components/LoadingPage';
import CashRegisterReportPage from '../pages/CashRegisterReportPage';

const FrontdeskReportBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        height: '100%',
      }}
    >
      <h2>Error : </h2>
      <p>{error.message}</p>
    </Box>
  );
};

export const reportCashierRoutePaths = (api: RootContextProps): RouteObject[] => [
  {
    path: REPORT_CASHIER_CASH_REGISTER_PATH,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <CashRegisterReportPage />
      </React.Suspense>
    ),
    errorElement: <FrontdeskReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t('report.menu.cash_register' as any)}
          path={REPORT_CASHIER_CASH_REGISTER_PATH}
        />
      ),
    },
  },
];
