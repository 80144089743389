import * as yup from 'yup';
import { CurrencyApplicationEnum } from '../../models';
export const schema = yup.object().shape({
  from: yup.string().nullable().required("Le champ 'from' est obligatoire."),
  to: yup.string().nullable().required("Le champ 'to' est obligatoire."),
  company: yup.mixed().nullable(),
  // rates: yup.array().of(yup.string()).required(),
  // rates: yup
  //   .array()
  //   .of(yup.string())
  //   .min(1, "Au moins une valeur est requise pour 'rates'.")
  //   .required(),
  roomType: yup.number().nullable(),
  with_revenue: yup.boolean().required(),

  currency: yup
    .mixed()
    .nullable()
    .when('with_revenue', {
      is: true,
      then: schema =>
        schema.required("Le champ 'currency' est obligatoire lorsque 'with_revenue' est activé."),
    }),

  currency_application: yup
    .mixed<CurrencyApplicationEnum>()
    .nullable()
    .when('currency', {
      is: (currency: { is_local: boolean }) => currency && currency.is_local === false,
      then: schema =>
        schema.required(
          "Le champ 'currency_application' est obligatoire si la devise sélectionnée n'est pas locale."
        ),
    }),

  fixed_at: yup
    .string()
    .nullable()
    .when('currency_application', {
      is: CurrencyApplicationEnum.FIXED,
      then: schema =>
        schema.required(
          "Le champ 'fixed_at' est obligatoire lorsque 'currency_application' est FIXED."
        ),
    }),
});
