import { AxiosInstance } from 'axios';
import { InvoiceChargeGroupService } from 'services';

export interface InvoiceChargeGroupServiceModel {
  getAll: ReturnType<typeof InvoiceChargeGroupService.getAll>;
  create: ReturnType<typeof InvoiceChargeGroupService.create>;
  update: ReturnType<typeof InvoiceChargeGroupService.update>;
  remove: ReturnType<typeof InvoiceChargeGroupService.remove>;
  toggleStatus: ReturnType<typeof InvoiceChargeGroupService.toggleStatus>;
}

export const initInvoiceChargeGroupService = (
  axios: AxiosInstance
): InvoiceChargeGroupServiceModel => ({
  getAll: InvoiceChargeGroupService.getAll(axios),
  create: InvoiceChargeGroupService.create(axios),
  update: InvoiceChargeGroupService.update(axios),
  remove: InvoiceChargeGroupService.remove(axios),
  toggleStatus: InvoiceChargeGroupService.toggleStatus(axios),
});
