import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const Calendar2Icon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.667.667c.368 0 .666.298.666.666V2h1.93c.73 0 1.33.556 1.397 1.268l.007.135V13.93c0 .775-.628 1.403-1.403 1.403H2.736a1.403 1.403 0 0 1-1.403-1.403V3.403C1.333 2.628 1.961 2 2.736 2h1.93v-.667a.667.667 0 1 1 1.334 0V2h4v-.667c0-.368.298-.666.667-.666zm2.666 6.666H2.666v6.597a.07.07 0 0 0 .043.065l.027.005h10.528a.07.07 0 0 0 .07-.07V7.333zm-8.667-4h-1.93a.07.07 0 0 0-.07.07V6h10.667V3.403a.07.07 0 0 0-.042-.064l-.027-.006h-1.932l.001.667A.667.667 0 1 1 10 4v-.667H6V4a.667.667 0 0 1-1.333 0v-.667z"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
