import { AxiosInstance } from 'axios';
import { DAILY_FORECAST_REPORT_ENDPOINT } from '../endpoint';
import { DailyForecastReportRequestModel } from '../models/DailyForecastReportRequestModel';

export const exportPDF =
  (axios: AxiosInstance) =>
  async (params?: DailyForecastReportRequestModel): Promise<void> => {
    return await axios
      .get(`${DAILY_FORECAST_REPORT_ENDPOINT}`, {
        params: { timestamp: new Date().getTime(), ...params },
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
      })
      .then(response => {
        const file = new Blob([response as any], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      });
  };
