import { AxiosInstance } from "axios";
import {
  ChainSettingServiceModel,
  initSettingService,
} from "./Services/ChainSettingServices";
import { ChainReservationServices, initChainReservationServices } from "./Services/ReservationsServices/GuestServices";
import {
  RevenueManagementServiceModel,
  initRevenueManagementService,
} from "./Services/RevenueManagement";
import { UserServiceModel, initUserService } from "./Services/UserService";

export interface ChainServiceModel {
  revenueManagement: RevenueManagementServiceModel;
  reservations: ChainReservationServices;
  settings: ChainSettingServiceModel;
  user: UserServiceModel;
}

export const initChainService = (axios: AxiosInstance): ChainServiceModel => ({
  revenueManagement: initRevenueManagementService(axios),
  reservations: initChainReservationServices(axios),
  settings: initSettingService(axios),
  user: initUserService(axios),
});
