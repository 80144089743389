// Setting Enpoints

import { REVENUE_MANAGEMENT_ROOT_ENDPOINT, SETTINGS_ROOT_ENDPOINT } from './RootEndpoint';

export const SETTINGS_CARD = `${SETTINGS_ROOT_ENDPOINT}/agency-card`;
export const SETTINGS_FINANCIAL = `${SETTINGS_ROOT_ENDPOINT}/financial`;
export const SETTINGS_REVENU_MANAGEMENT = `${SETTINGS_ROOT_ENDPOINT}/revenue-management`;
export const SETTINGS_CURRENCY = `${SETTINGS_ROOT_ENDPOINT}/currency`;
export const SETTINGS_RESERVATION = `${SETTINGS_ROOT_ENDPOINT}/reservation`;
export const SETTINGS_RATES = `${SETTINGS_ROOT_ENDPOINT}/rate`;
export const SETTINGS_RATES_RULES = `${SETTINGS_RATES}/rule`;
export const SETTINGS_ROOMS = `${SETTINGS_ROOT_ENDPOINT}/rooms`;
export const SETTINGS_INFOS = `${SETTINGS_ROOT_ENDPOINT}/infos`;

// Settings - Financial
export const SETTINGS_FINANCIAL_PAYMENT_METHOD = `${SETTINGS_FINANCIAL}/payment-method`;
export const SETTINGS_FINANCIAL_PAYMENT_TYPE = `${SETTINGS_FINANCIAL}/payment-type`;
export const SETTINGS_FINANCIAL_PAYMENT_TYPE_CHARGE_CODE = `${SETTINGS_FINANCIAL}/payment-type/%d/charge-code`;
export const SETTINGS_FINANCIAL_CURRENCY = `${SETTINGS_FINANCIAL}/currency`;
export const SETTINGS_FINANCIAL_CASH_REGISTER_OPERTATION_PATH = `${SETTINGS_FINANCIAL}/cash-register-operations`;
export const SETTINGS_FINANCIAL_CASH_REGISTER_PATH = `${SETTINGS_FINANCIAL}/cash-registers`;
export const SETTINGS_FINANCIAL_CASH_OPERATION_TYPE_PATH = `${SETTINGS_FINANCIAL}/operation-types`;
export const SETTINGS_FINANCIAL_CASH_CATGEORY_PATH = `${SETTINGS_FINANCIAL}/cash-categories`;
export const SETTINGS_FINANCIAL_CASHIER = `${SETTINGS_FINANCIAL}/cashiers`;
export const SETTINGS_FINANCIAL_ACCOUNT_GROUP = `${SETTINGS_FINANCIAL}/account-groups`;
export const SETTINGS_FINANCIAL_ACCOUNT_NUMBER = `${SETTINGS_FINANCIAL}/account-numbers`;
// export const SETTINGS_FINANCIAL_CASH_CATGEORY_PATH = `${SETTINGS_FINANCIAL}/cash-categories`;

// Settings - Revenue Management
export const SETTINGS_REVENU_MANAGEMENT_ITEMS = `${SETTINGS_REVENU_MANAGEMENT}/item`;
export const SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE = `${SETTINGS_REVENU_MANAGEMENT}/discounts`;
export const SETTINGS_REVENU_MANAGEMENT_DISCOUNT_TYPE_SEARCH = `${SETTINGS_REVENU_MANAGEMENT}/discounts/charges/search`;
export const SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE = `${SETTINGS_REVENU_MANAGEMENT}/charge-code`;
export const SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE_FOR_STAY_CARD = `${SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE}/get-list-for-stay-card`;
export const SETTINGS_REVENU_MANAGEMENT_CHARGE_CODE_ITEMS = `${SETTINGS_REVENU_MANAGEMENT}/charge-code/get-list-for-items`;
export const SETTINGS_REVENU_MANAGEMENT_CHARGE_GROUP = `${SETTINGS_REVENU_MANAGEMENT}/charge-group`;
export const SETTINGS_REVENU_MANAGEMENT_INVOICE_CHARGE_GROUP = `${SETTINGS_REVENU_MANAGEMENT}/invoice-charge-group`;
export const SETTINGS_REVENU_MANAGEMENT_BILLING_GROUP = `${SETTINGS_REVENU_MANAGEMENT}/billing-group`;
export const SETTINGS_REVENU_MANAGEMENT_ADDON = `${SETTINGS_REVENU_MANAGEMENT}/package`;
export const SETTINGS_REVENU_MANAGEMENT_ADDON_ITEM_PACKAGE = `${SETTINGS_REVENU_MANAGEMENT}/package-item`;
export const SETTINGS_REVENU_MANAGEMENT_PACKAGE = `${SETTINGS_REVENU_MANAGEMENT}/package`;

// Settings - Reservation (To check with api: there is an endpoint for ${SETTINGS}/reservation ?)
export const SETTINGS_RESERVATION_SEGMENT_MARKET_SEGMENT = `${SETTINGS_ROOT_ENDPOINT}/market-segment`;
export const SETTINGS_RESERVATION_MARKET_CODE = `${SETTINGS_ROOT_ENDPOINT}/market-code`;
export const SETTINGS_RESERVATION_SOURCE = `${SETTINGS_RESERVATION}/reservation-source`;
export const SETTINGS_RESERVATION_ROOT_ENDPOINT_STATE = `${SETTINGS_RESERVATION}/reservation-state`;
export const SETTINGS_RESERVATION_AEROPORT = `${SETTINGS_RESERVATION}/reservation-airport`;
export const SETTINGS_RESERVATION_VIP_CATEGORY = `${SETTINGS_RESERVATION}/reservation-vip-category`;
export const SETTINGS_RESERVATION_ROOM_LIKE_AND_FEATURE = `${SETTINGS_RESERVATION}/preference`;
export const SETTINGS_RESERVATION_ORIGIN_OF_BOOKING = `${SETTINGS_RESERVATION}/reservation-origin`;
export const SETTINGS_RESERVATION_SEGMENT_MARKET_CODE = `${SETTINGS_ROOT_ENDPOINT}/market-code`;
export const SETTINGS_RESERVATION_SEGMENT_MARKET_CODE_RATES = `${SETTINGS_ROOT_ENDPOINT}/market-code/rates/%d`;
export const SETTINGS_RESERVATION_SEGMENT_MARKET_CODE_GET_BY_PARENT = `${SETTINGS_ROOT_ENDPOINT}/market-code/%1$s/by-parent`;
export const SETTINGS_RESERVATION_MARKET_RATE = `${REVENUE_MANAGEMENT_ROOT_ENDPOINT}/market/%1$s/rate`;
export const SETTINGS_RESERVATION_MARKET_RATE_PATCH = `${REVENUE_MANAGEMENT_ROOT_ENDPOINT}/market/%1$s/rate/%2$s`;

// Settings - Rates
export const SETTINGS_RATES_COMPLIMENTARY_CATEGORY = `${SETTINGS_RATES}/complimentary-category`;
export const SETTINGS_RATES_SEASON = `${SETTINGS_RATES}/season`;
export const SETTINGS_RATES_RATE_TYPE = `${SETTINGS_RATES}/rate-type`;
export const SETTINGS_RATES_RULE_CANCELATION_PENALITY = `${SETTINGS_RATES_RULES}/cancellation-penalty`;
export const SETTINGS_RATES_RULE_NOS_SHOW_PENALITY = `${SETTINGS_RATES_RULES}/no-show-penalty`;
export const SETTINGS_RATES_RULE_DEPOSIT_REQUEST = `${SETTINGS_RATES_RULES}/deposit-request`;
export const SETTINGS_RATES_AGE_RANGE = `${SETTINGS_RESERVATION}/age-range`;
export const SETTINGS_RATES_RANGE_TEMPLATE = `${SETTINGS_RATES}/range-template`;
export const SETTINGS_RATES_RANGE_TEMPLATE_DEPENDENCY = `/settings/dependencies/range-templates`;
export const SETTINGS_RATES_ITEMS_PACKAGES = `${SETTINGS_RATES}/package-item`;
export const SETTINGS_RATES_BOARD_SERVICE = `${SETTINGS_RATES}/board`;
export const SETTINGS_RATES_BOARD_SERVICE_SETTINGS_SERVICE = `${SETTINGS_REVENU_MANAGEMENT}/package`;
export const SETTINGS_RATES_FREQUENCY = `${SETTINGS_RATES}/frequency`;
export const SETTINGS_RATES_RULES_AND_PROMOTION = `${SETTINGS_RATES}/promotion`;
export const SETTINGS_RATES_RESTRICTION = `${SETTINGS_RATES_RULES}/restriction`;

// Settings - Card - Group
export const SETTINGS_CARD_GROUP = `${SETTINGS_CARD}/group`;

// Settings - Card - Group
export const SETTINGS_CARD_GROUP_RATE = `/revenue-management/group/%s/rate`;
export const SETTINGS_CARD_GROUP_RATE_PATCH = `/revenue-management/group/%s/rate/%s`;

// Settings - Card - Guest Group Field
export const SETTINGS_CARD_GUEST_GROUP_FIELD = `${SETTINGS_ROOT_ENDPOINT}/guest-card/fields`;

// Settings - Rooms
export const SETTINGS_ROOMS_ROOM_TYPE = `${SETTINGS_ROOMS}/room-type`;

// Settings - Card
export const SETTINGS_APPLICATION_SECURITY = `${SETTINGS_ROOT_ENDPOINT}/security`;
export const SETTINGS_APPLICATION_LANGUAGE = `${SETTINGS_ROOT_ENDPOINT}/language`;
export const SETTINGS_APPLICATION_INTERFACE_ACCESSIBILITY = `${SETTINGS_ROOT_ENDPOINT}/interfaces-accessibility`;
export const SETTINGS_APPLICATION_HOUSE_KEEPING_CONFIG = `${SETTINGS_ROOT_ENDPOINT}/rooms/state`;
export const SETTINGS_APPLICATION_HOUSE_KEEPING_CONFIG_ID = `${SETTINGS_ROOT_ENDPOINT}/rooms/state/%s`;
export const SETTINGS_APPLICATION_COUNTER = `${SETTINGS_ROOT_ENDPOINT}/financial/counters`;
export const SETTINGS_APPLICATION_COUNTER_LINK = `${SETTINGS_ROOT_ENDPOINT}/financial/link_counters`;

export const UPLOAD_FILE = 'settings/upload';

// Settings user
export const SETTINGS_USER = `${SETTINGS_ROOT_ENDPOINT}/user`;

// Settings user Change Password
export const SETTINGS_USER_CHANGE_PASSWORD = `${SETTINGS_USER}/%s/password`;

// Settings user
export const SETTINGS_ROLE = `${SETTINGS_ROOT_ENDPOINT}/role`;
export const SETTINGS_USER_MY_PERMISSIONS = `${SETTINGS_USER}/my-permissions`;
export const SETTINGS_USER_PERMISSIONS = `${SETTINGS_USER}/%s/permissions`;
export const SETTINGS_ROLE_PERMISSIONS = `${SETTINGS_ROLE}/%s/permissions`;

// Settings : Department And Staff
export const SETTINGS_DEPARTMENT_STAFF = `${SETTINGS_ROOT_ENDPOINT}/department-and-staff`;

export const SETTINGS_DEPARTMENT_STAFF_GET_ALL = `${SETTINGS_DEPARTMENT_STAFF}/employee-department`;
export const SETTINGS_DEPARTMENT_STAFF_DEPARTMENT_GET_ALL = `${SETTINGS_DEPARTMENT_STAFF}/department`;
export const SETTINGS_DEPARTMENT_STAFF_GET_STAFF_ALL = `${SETTINGS_DEPARTMENT_STAFF}/employee`;
