import {
  REPORT_FRONTDESK_ARRIVAL_PATH,
  REPORT_FRONTDESK_DAILY_REPORT_PATH,
  REPORT_FRONTDESK_DEPARTURE_PATH,
  REPORT_FRONTDESK_INHOUSE_PATH,
  REPORT_FRONTDESK_LOGS_REPORT_PATH,
} from '@constants/Navigation/AppPaths/ReportPaths';
import { Box } from '@mui/material';
import { RootContextProps } from 'components/RootContainer';
import i18next from 'i18next';
import React, { FC } from 'react';
import { RouteObject, useRouteError } from 'react-router-dom';
import { Breadcrumb } from 'routing/components/Breadcrumb';
import LoadingPage from '../../../../../components/LoadingPage';
import ArrivalReportPage from '../pages/ArrivalReportPage';
import DailyReportPage from '../pages/DailyReportPage';
import DepartureReportPage from '../pages/DepartureReportPage';
import InHouseReportPage from '../pages/InHouseReportPage';
import LogsReportPage from '../pages/LogsReportPage';

const FrontdeskReportBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        height: '100%',
      }}
    >
      <h2>Error : </h2>
      <p>{error.message}</p>
    </Box>
  );
};

export const reportFrontdeskRoutePaths = (api: RootContextProps): RouteObject[] => [
  {
    path: REPORT_FRONTDESK_ARRIVAL_PATH,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <ArrivalReportPage />
      </React.Suspense>
    ),
    errorElement: <FrontdeskReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t('report.menu.arrival' as any)}
          path={REPORT_FRONTDESK_ARRIVAL_PATH}
        />
      ),
    },
  },

  {
    path: REPORT_FRONTDESK_DEPARTURE_PATH,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <DepartureReportPage />
      </React.Suspense>
    ),
    errorElement: <FrontdeskReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t('report.menu.departure' as any)}
          path={REPORT_FRONTDESK_DEPARTURE_PATH}
        />
      ),
    },
  },

  {
    path: REPORT_FRONTDESK_INHOUSE_PATH,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <InHouseReportPage />
      </React.Suspense>
    ),
    errorElement: <FrontdeskReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t('report.menu.inhouse' as any)}
          path={REPORT_FRONTDESK_INHOUSE_PATH}
        />
      ),
    },
  },
  {
    path: REPORT_FRONTDESK_DAILY_REPORT_PATH,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <DailyReportPage />
      </React.Suspense>
    ),
    errorElement: <FrontdeskReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t('report.menu.daily_report' as any)}
          path={REPORT_FRONTDESK_DAILY_REPORT_PATH}
        />
      ),
    },
  },
  {
    path: REPORT_FRONTDESK_LOGS_REPORT_PATH,
    element: (
      <React.Suspense fallback={<LoadingPage />}>
        <LogsReportPage />
      </React.Suspense>
    ),
    errorElement: <FrontdeskReportBoundary />,
    handle: {
      crumb: () => (
        <Breadcrumb
          label={i18next.t('report.menu.logs' as any)}
          path={REPORT_FRONTDESK_LOGS_REPORT_PATH}
        />
      ),
    },
  },
];
