import { AxiosInstance } from "axios";
import { CardGroupService } from "services/Reservations";

export interface CardGroupServiceModel {
  getAll: ReturnType<typeof CardGroupService.getAll>;
  getOneById: ReturnType<typeof CardGroupService.getOneById>;
  update: ReturnType<typeof CardGroupService.update>;
  create: ReturnType<typeof CardGroupService.create>;
  getAllPaginated: ReturnType<typeof CardGroupService.getAllPaginated>;
}

export const initCardGroupService = (axios: AxiosInstance): CardGroupServiceModel => ({
  getAll: CardGroupService.getAll(axios),
  getOneById: CardGroupService.getOneById(axios),
  update: CardGroupService.update(axios),
  create: CardGroupService.create(axios),
  getAllPaginated: CardGroupService.getAllPaginated(axios),
});
