import { ArrivalReportRequestModel } from '../api/models/ArrivalReportRequestModel';
import { ArrivalReportFormModel } from '../models';

export const dataFormToRequest = (data: ArrivalReportFormModel): ArrivalReportRequestModel => ({
  from: data.from,
  to: data.to,
  card_ids: data.card_ids.map(card => card.id),
  rate_ids: data.rate_ids,
  room_type_ids: data.room_type_ids,
  state: data.state,
  price: data.price,
  nationality_ids: data.nationality_ids,
});
