import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, ReactNode } from 'react';
import { DepartureReportStateEnum } from '../../../models';

export const StateField: FC<{
  value: DepartureReportStateEnum | undefined;
  onChange: (value: DepartureReportStateEnum | undefined) => void;
  error?: ReactNode;
}> = ({ value, onChange, error }) => {
  return (
    <FormControl variant="standard" fullWidth className="background">
      <InputLabel id="reservation-filter-room-types">State</InputLabel>
      <Select
        error={Boolean(error)}
        labelId="reservation-filter-room-types"
        value={value}
        onChange={e => {
          onChange(e.target.value ? (e.target.value as DepartureReportStateEnum) : undefined);
        }}
      >
        <MenuItem firstItem value="all">
          All
        </MenuItem>
        <MenuItem firstItem value={DepartureReportStateEnum.DUE_OUT}>
          Due out
        </MenuItem>
        <MenuItem firstItem value={DepartureReportStateEnum.CHECKED_OUT}>
          Checked out
        </MenuItem>
      </Select>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
