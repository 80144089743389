import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { RootContext } from 'components/RootContainer';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const RateField: FC<{
  value: number[] | null;
  onChange: (value: string[] | null) => void;
  error?: ReactNode;
}> = ({ value, onChange, error }) => {
  const {
    HotelApi: {
      revenueManagement: { rate: RateService },
    },
  } = useContext(RootContext);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['get-rates'],
    queryFn: () => {
      return RateService.rate.getAll({
        limit: 500,
      });
    },
  });

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value;
    onChange(Array.isArray(selectedValues) && selectedValues.length > 0 ? selectedValues : null);
  };

  if (isLoading) {
    return <>...</>;
  }

  return (
    <FormControl variant="standard" sx={{ width: '50%' }} className="background">
      <InputLabel id="rate-select-label">Rate</InputLabel>
      <Select
        error={Boolean(error)}
        labelId="rate-select-label"
        id="rate-select"
        multiple
        value={value?.map(String) ?? []}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {(data || []).map(item => (
          <MenuItem key={item.id} value={String(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
