import { useRootContext } from 'components/RootContainer';
import { FC, ReactNode, createContext, useCallback, useContext } from 'react';

export interface RootACLContextModel {
  isAllowed: (permission: string) => boolean;
}

export const RootACLContext = createContext<RootACLContextModel>({} as RootACLContextModel);
export const useRootACLContext = () => useContext(RootACLContext);
export const RootACLProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Get the user information from the root context.
  const { user } = useRootContext();

  // Helper function to generate all possible prefixes of a permission string.
  // For example, "a.b.c" -> ["a", "a.b", "a.b.c"].
  const generatePrefixes = (input: string): string[] => {
    return input.split('.').reduce((acc: string[], part: string, index: number) => {
      const prefix = index === 0 ? part : `${acc[index - 1]}.${part}`;
      return [...acc, prefix];
    }, []);
  };

  // Helper function to check if two arrays have at least one common item.
  const hasCommonItem = (arr1: string[], arr2: string[]): boolean => {
    const set2 = new Set(arr2);
    // console.log({ arr1, arr2 });

    return arr1?.some(item => set2?.has(item));
  };
  const isAllowed = useCallback(
    (permission: string) => {
      // return true;
      return hasCommonItem(
        Array.isArray(user?.user?.permissions)
          ? user?.user?.permissions
          : Object.values(user?.user?.permissions) ?? [],
        generatePrefixes(permission)
      );
    },
    [user.user.permissions]
  ); // Dependency array ensures this function is recalculated when user permissions change.

  // Provide the `isAllowed` function to the children via the context.
  return (
    <RootACLContext.Provider
      value={{
        isAllowed,
      }}
    >
      {children}
    </RootACLContext.Provider>
  );
};

export default RootACLProvider;
