import { AxiosInstance } from 'axios';
import { CounterLinksService } from 'services';

export interface CounterSettingsServiceModel {
  getAll: ReturnType<typeof CounterLinksService.getAll>;
  update: ReturnType<typeof CounterLinksService.update>;
  attachTax: ReturnType<typeof CounterLinksService.attachTax>;
  detachTax: ReturnType<typeof CounterLinksService.detachTax>;
}

export const initCounterLinkService = (axios: AxiosInstance): CounterSettingsServiceModel => ({
  getAll: CounterLinksService.getAll(axios),
  update: CounterLinksService.update(axios),
  attachTax: CounterLinksService.attachTax(axios),
  detachTax: CounterLinksService.detachTax(axios),
});
