import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { useCashierAuthManager } from "components/CashierAuthManager";
import { CashierOpenMode } from "components/CashierAuthManager/models";
import PrinterIcon from "components/SvgIcons/PrinterIcon";
import { ExchangeResponseModel } from "models/Cashier/TransactionsModels";
import { CurrencyModel } from "models/Settings/Financial/CurrencyModel";
import React from "react";
import {
  intlNumberFormat,
  intlNumberFormatWithoutSymbol,
} from "utils/intlNumberFormat";

export interface Detail {
  local_currency: CurrencyModel;
  currency: CurrencyModel;
  local_amount: number;
  amount_exchange: number;
  rate: string;
}

interface DataRowProps {
  row: ExchangeResponseModel;
  index: number;
  exportPdf: () => void
}

const DataRow: React.FC<DataRowProps> = ({ row, index, exportPdf }) => {
  const { mode } = useCashierAuthManager();



  return (
    <TableRow
      key={index}
      sx={{
        borderRadius: "0.5rem",
        backgroundColor: "transparent",
        height: "2.5rem",
        margin: "0.5rem 1rem",
        padding: "0.25rem 0.5rem 0.25rem 1rem",
        boxShadow: "0 1px 0 0 #d2d7de",
        " td": { backgroundColor: "colors.gray.o10" },
        " td:first-of-type": {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
        " td:last-of-type": {
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      }}
    >
      <TableCell
        sx={{
          fontSize: "0.875rem",
          height: "2.5rem",
          pl: "1.5rem",
          textAlign: "left", // Align text to the start
          alignContent: "flex-start", // Align content to the start
        }}
      >
        {index + 1}
      </TableCell>
      <TableCell
        sx={{
          fontSize: "0.875rem",
          height: "2.5rem",
          textAlign: "left",
          alignContent: "flex-start",
        }}
      >
        {row.date_time}
      </TableCell>
      <TableCell
        sx={{
          fontSize: "0.875rem",
          height: "2.5rem",
          textAlign: "left",
          alignContent: "flex-start",
        }}
      >
        {row.details?.map((item) => (
          <Typography display="block" key={item.currency.name}>
            {item.currency.name}
          </Typography>
        ))}
      </TableCell>
      <TableCell
        sx={{
          fontSize: "0.875rem",
          height: "2.5rem",
          textAlign: "left",
          alignContent: "flex-start",
        }}
      >
        {row.details?.map((item) => (
          <Typography display="block" key={item.rate}>
            {item.rate}
          </Typography>
        ))}
      </TableCell>
      <TableCell
        sx={{
          fontSize: "0.875rem",
          height: "2.5rem",
          textAlign: "left",
          alignContent: "flex-start",
        }}
      >
        {row.details?.map((item) => (
          <Typography display="block" key={item.amount_exchange}>
            {intlNumberFormatWithoutSymbol(
              "fr",
              String(item?.currency?.code),
              Number(item.amount_exchange)
            )}
          </Typography>
        ))}
      </TableCell>
      <TableCell
        sx={{
          fontSize: "0.875rem",
          height: "2.5rem",
          textAlign: "left",
          alignContent: "flex-start",
        }}
      >
        <Typography fontWeight="bold">
          {row.details?.map((item) => (
            <Typography
              display="block"
              fontWeight="bold"
              key={item.local_amount}
            >
              {intlNumberFormat(
                "fr",
                String(item?.local_currency?.code),
                Number(item.local_amount)
              )}
            </Typography>
          ))}
        </Typography>
      </TableCell>

      <TableCell
        sx={{
          fontSize: "0.875rem",
          height: "2.5rem",
          textAlign: "left",
          alignContent: "flex-start",
        }}
      >
        <IconButton

          onClick={() => exportPdf()}
          size="small" disabled={mode === CashierOpenMode.READ}>
          <PrinterIcon sx={{ color: "colors.purple.o70", fontSize: 18 }} />
        </IconButton>
      </TableCell>

      {/* <TableCell
        sx={{
          fontSize: "0.875rem",
          height: "2.5rem",
          textAlign: "left",
          alignContent: "flex-start",
        }}
      >
        <IconButton size="small" disabled={mode === CashierOpenMode.READ}>
          <CancelIcon fontSize="small" />
        </IconButton>
      </TableCell> */}
    </TableRow>
  );
};

export default DataRow;
