export interface LogsReportRequestModel {
  from: string | null;
  to: string | null;
  operations: Array<LogsReportOperationEnum>;
  users: Array<number>;
}

export enum LogsReportOperationEnum {
  ASSIGN = 'assign',
  CHANGE_ROOM = 'change_room',
  MOVE_ROOM = 'move_room',
  CHECKIN = 'checkin',
  CHECKOUT = 'checkout',
}
