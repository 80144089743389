import { AxiosInstance } from 'axios';
import {
  FinancialAccountServiceType,
  initFinancialAccountService,
} from 'pages/ReservationModule/pages/FinancialAccountModule/api';
import { ForecastService, initForecastService } from './Forecast';
import { FrontDeskService, initFrontDeskService } from './FrontDesk';
import { GroupReservationServiceType, initGroupReservationService } from './GroupReservation';
import {
  ReservationQuickReservationService,
  initReservationQuickReservationService,
} from './QuickReservation';
import {
  ReservationDependenciesServiceModel,
  initReservationDependenciesService,
} from './ReservationDependenciesService';
import {
  ReservationReservationService,
  initReservationReservationService,
} from './ReservationService';

export interface ReservationsService {
  reservation: ReservationReservationService;
  quickReservation: ReservationQuickReservationService;
  dependencies: ReservationDependenciesServiceModel;
  frontDesk: FrontDeskService;
  forecast: ForecastService;
  groupReservation: GroupReservationServiceType;
  //financialAccount: FinancialReservationServiceType;
  financialAccount: FinancialAccountServiceType;
}

export const initReservationsService = (axios: AxiosInstance): ReservationsService => ({
  reservation: initReservationReservationService(axios),
  quickReservation: initReservationQuickReservationService(axios),
  dependencies: initReservationDependenciesService(axios),
  frontDesk: initFrontDeskService(axios),
  forecast: initForecastService(axios),
  groupReservation: initGroupReservationService(axios),
  // financialAccount: initFinancialReservationService(axios),
  financialAccount: initFinancialAccountService(axios),
});
