import {
  REPORT_CASHIER_PATH,
  REPORT_FRONTDESK_PATH,
  REPORT_RESERVATION_DAILY_FORECAST_PATH,
  REPORT_RESERVATION_PATH,
} from '@constants/Navigation/AppPaths/ReportPaths';
import { Box } from '@mui/material';
import { RootContextProps } from 'components/RootContainer';
import { Crumb } from 'components/TranslatableCrub';
import React, { FC, useEffect } from 'react';
import { Outlet, RouteObject, useNavigate, useRouteError } from 'react-router-dom';
import { reportCashierRoutePaths } from '../pages/ReportCashierModule/routing/routes';
import { reportFrontdeskRoutePaths } from '../pages/ReportFrontdeskModule/routing/routes';
import { reportReservationRoutePaths } from '../pages/ReportRservationModule/routing/routes';

const LandingPage: FC = (): JSX.Element => {
  let navigate = useNavigate();
  // redirect to daily reservation > daily forecast
  useEffect(() => {
    navigate(REPORT_RESERVATION_DAILY_FORECAST_PATH, { replace: true });
  }, []);

  return <>LandingPage</>;
};

const ReportBoundary: FC = (): JSX.Element => {
  let error = useRouteError() as Error;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        height: '100%',
      }}
    >
      <h2>Error : </h2>
      <p>{error.message}</p>
    </Box>
  );
};

export const reportRoutePaths = (api: RootContextProps): RouteObject[] => [
  {
    index: true,
    element: <LandingPage />,
  },
  {
    path: `${REPORT_RESERVATION_PATH}`,
    element: (
      <React.Suspense fallback={<>...</>}>
        <Outlet />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="report.menu.reservation" path={null} />,
    },
    children: reportReservationRoutePaths(api),
  },
  {
    path: `${REPORT_FRONTDESK_PATH}`,
    element: (
      <React.Suspense fallback={<>...</>}>
        <Outlet />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="report.menu.frontdesk" path={null} />,
    },
    children: reportFrontdeskRoutePaths(api),
  },
  {
    path: `${REPORT_CASHIER_PATH}`,
    element: (
      <React.Suspense fallback={<>...</>}>
        <Outlet />
      </React.Suspense>
    ),
    handle: {
      crumb: () => <Crumb label="report.menu.cashier" path={null} />,
    },
    children: reportCashierRoutePaths(api),
  },
];
