import { AxiosInstance } from 'axios';
import { ReservationListReportRequestModel } from '../models/ReservationListReportRequestModel';
import { RESERVATION_LIST_REPORT_ENDPOINT } from '../endpoint';

export const exportPDF =
  (axios: AxiosInstance) =>
  async (params?: ReservationListReportRequestModel): Promise<void> => {
    return await axios
      .get(`${RESERVATION_LIST_REPORT_ENDPOINT}`, {
        params: { timestamp: new Date().getTime(), ...params },
        responseType: 'arraybuffer',
        headers: { Accept: 'application/pdf' },
      })
      .then(response => {
        const file = new Blob([response as any], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      });
  };
