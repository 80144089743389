import { ReservationsEndpoint } from '@constants/ApiEndpoints';
import { AxiosInstance } from 'axios';
import { SearchResaBillResponseModel } from 'components/BillsManager/models/SearchResaBillResponseModel';
import { AddPaymentsToBillFormModel } from 'components/DialogAddPaymentsToBill/models/AddPaymentsToBillFormModel';
import {
  StayCardbillGroupUpdateModel,
  StayCardBillPaymentEdit,
  StayCardBillTransfer,
  StayCardChargesModel,
  StayCardModel,
} from 'models/Reservations/StayCard/ReservationStayCardModel';
import ReservationPaymentMethodCreateModel from 'pages/ReservationModule/SharedModels/ReservationPaymentMethodCreateModel';
import { sprintf } from 'sprintf-js';

interface AddPaymentModel extends ReservationPaymentMethodCreateModel {
  isCardGuest?: boolean;
}

export type AddPaymentFormModel = {
  data: AddPaymentModel[];
};

export default AddPaymentModel;

export type BasicUpdateParams = {
  identifier: number | string;
  billId: number;
};

export type UpdateParams = BasicUpdateParams & {
  // paymentId: number | string;
  transactionId: number;
  data: StayCardBillPaymentEdit;
};

export type ChargeTransferParams = BasicUpdateParams & {
  // transactionId: number | string;
  data: StayCardBillTransfer;
};

type PaymentTransferParams = BasicUpdateParams & {
  // paymentId: number | string;
  data: StayCardBillTransfer;
};

export const getAll =
  (axios: AxiosInstance) =>
  async (guestId: number, identifier: number | string): Promise<StayCardChargesModel> => {
    return await axios
      .get(sprintf(ReservationsEndpoint.RESERVATION_STAY_CARD_CHARGES_GET, guestId), {
        params: { identifier },
      })
      .then(({ data }) => data);
  };

/**
 * BILLING GROUP SERVICES
 * @returns Update function for create, update & delete functions
 */
export const updateBillingGroup =
  (axios: AxiosInstance) =>
  async (params: {
    billing_routes: StayCardbillGroupUpdateModel;
    identifier: number;
  }): Promise<StayCardModel> => {
    return await axios
      .put(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_BILLING_GROUPS_UPDATE,
          params.identifier
        ),
        { billing_routes: params.billing_routes }
      )
      .then(({ data }) => data);
  };
/******************************************************************** */

export const search =
  (axios: AxiosInstance) =>
  async (
    reservationRoomId: number,
    search: string
  ): Promise<
    // StayCardBillSearch[] |
    SearchResaBillResponseModel[]
  > => {
    return await axios
      .get(sprintf(ReservationsEndpoint.RESERVATION_STAY_CARD_CHARGES_SEARCH, reservationRoomId), {
        params: { search },
      })
      .then(({ data }) => data);
  };

export const update =
  (axios: AxiosInstance) =>
  async (params: UpdateParams): Promise<StayCardModel> => {
    return await axios
      .patch(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_BILL_UPDATE,
          params.identifier,
          params.billId,
          params.transactionId
        ),
        params.data
      )
      .then(({ data }) => data);
  };

export const transferCharge =
  (axios: AxiosInstance) =>
  async (params: ChargeTransferParams): Promise<StayCardModel> => {
    return await axios
      .patch(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_BILL_CHARGE_TRANSFERT,
          params.identifier,
          params.billId
          // params.transactionId
        ),
        params.data
      )
      .then(({ data }) => data);
  };

export const transferPayment =
  (axios: AxiosInstance) =>
  async (params: PaymentTransferParams): Promise<StayCardModel> => {
    return await axios
      .patch(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_BILL_PAYMENTS_TRANSFERT,
          params.identifier,
          params.billId
          // params.paymentId
        ),
        params.data
      )
      .then(({ data }) => data);
  };

export const addNewBill =
  (axios: AxiosInstance) =>
  async ({
    reservationRoomId,
    ...data
  }: {
    reservationRoomId: number;
    resource_id: number;
    resource_type: string;
  }) => {
    return await axios
      .post(
        sprintf(ReservationsEndpoint.RESERVATION_STAY_CARD_BILL_CREATE, reservationRoomId),
        data
      )
      .then(({ data }) => data);
  };
export interface PostTransactionModel {
  reservationRoomId: number;
  billId: number;
  arAcount?: number | undefined;
  data: {
    items: {
      id: number;
      quantity: number;
      type: 'item' | 'package';
    }[];
    charge_codes: {
      id: number;
      amount: number;
      justification: string;
    }[];
  };
}
export const postTransaction =
  (axios: AxiosInstance) =>
  async ({ reservationRoomId, billId, arAcount, data }: PostTransactionModel) => {
    return await axios
      .post(
        sprintf(
          arAcount
            ? ReservationsEndpoint.RESERVATION_STAY_CARD_BILL_POST_TRANSACTION_AR_ACCOUNT
            : ReservationsEndpoint.RESERVATION_STAY_CARD_BILL_POST_TRANSACTION,
          reservationRoomId,
          billId,
          arAcount
        ),
        { ...data }
      )
      .then(({ data }) => data);
  };

export const transferTransactionsToCompany =
  (axios: AxiosInstance) =>
  async ({ reservationRoomId, billId }: { reservationRoomId: number; billId: number }) => {
    return await axios
      .patch(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_BILL_TRANSFER_TRANSACTION_TO_COMPANY,
          reservationRoomId,
          billId
        )
      )
      .then(({ data }) => data);
  };

export const postPayment =
  (axios: AxiosInstance) =>
  async ({
    data,
    reservationRoomId,
    billId,
  }: {
    reservationRoomId: number;
    billId: number;
    data: AddPaymentsToBillFormModel;
  }) => {
    return await axios
      .post(
        sprintf(
          ReservationsEndpoint.RESERVATION_STAY_CARD_BILL_POST_PAYMENT,
          reservationRoomId,
          billId
        ),
        { ...data }
      )
      .then(({ data }) => data);
  };

export const deleteBill =
  (axios: AxiosInstance) =>
  async ({ reservationRoomId, billId }: { reservationRoomId: number; billId: number }) => {
    return await axios
      .delete(
        sprintf(ReservationsEndpoint.RESERVATION_STAY_CARD_DELETE_BILL, reservationRoomId, billId)
      )
      .then(({ data }) => data);
  };
