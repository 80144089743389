import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

export const HotelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 1a.26.26 0 0 0-.24.154l-.245.54-.747.115a.264.264 0 0 0-.147.447l.506.505-.106.63a.264.264 0 0 0 .387.275L12 3.344l.592.323a.264.264 0 0 0 .386-.275l-.105-.63.506-.506a.264.264 0 0 0-.147-.447l-.747-.115-.245-.54A.26.26 0 0 0 12 1zM8 2.5a.26.26 0 0 0-.24.154l-.245.54-.747.115a.264.264 0 0 0-.147.447l.506.505-.106.63a.264.264 0 0 0 .387.275L8 4.844l.592.323a.264.264 0 0 0 .386-.275l-.105-.63.506-.506a.264.264 0 0 0-.147-.447l-.747-.115-.245-.54A.26.26 0 0 0 8 2.5zm8 0a.26.26 0 0 0-.24.154l-.245.54-.747.115a.264.264 0 0 0-.147.447l.506.506-.105.63a.264.264 0 0 0 .386.275L16 4.844l.592.322a.264.264 0 0 0 .387-.274l-.106-.631.506-.505a.264.264 0 0 0-.147-.447l-.747-.116-.245-.539A.26.26 0 0 0 16 2.5zm-4.036 2.501a.75.75 0 0 0-.291.074L4.548 8.53l-.002.001A2.758 2.758 0 0 0 3 11.005V21.25c0 .684.566 1.25 1.25 1.25h15.5c.684 0 1.25-.566 1.25-1.25V11.005a2.758 2.758 0 0 0-1.546-2.474h-.002l-7.125-3.456a.75.75 0 0 0-.363-.074zM12 6.584l6.796 3.295c.431.21.704.642.704 1.126V21h-6v-3.75a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0-.75.75V21h-6v-9.995c0-.484.272-.914.702-1.125l.002-.001L12 6.584zM6.5 11.5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2A.5.5 0 0 0 9 14v-2a.5.5 0 0 0-.5-.5h-2zm4.5 0a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-2zm4.5 0a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-2zm-9 5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2A.5.5 0 0 0 9 19v-2a.5.5 0 0 0-.5-.5h-2zm9 0a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-2z" fillRule="nonzero" />
    </SvgIcon>
  );
};
