import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { CurrencyApplicationEnum, ReservationListReportFormModel } from '../../../models';
import { useFormContext } from 'react-hook-form';

export const FixedAtField: FC<
  TextFieldProps & {
    value: string | null;
    onChange: (value: string | null) => void;
  }
> = ({ value, onChange, ...props }) => {
  const { watch } = useFormContext<ReservationListReportFormModel>();
  const selectedCurrencyApplication = watch('currency_application');
  const disabled =
    selectedCurrencyApplication === null ||
    selectedCurrencyApplication === CurrencyApplicationEnum.DAILY;

  return (
    <TextField
      disabled={disabled}
      value={value ?? ''}
      onChange={e => onChange(e.target.value)}
      variant="standard"
      fullWidth
      label={'Fixed At'}
      helperText={props?.helperText}
    />
  );
};
