import { AxiosInstance } from 'axios';
import {
  FinancialAccountCreateServiceType,
  initFinancialAccountCreateService,
} from '../pages/CreatePage/api';
import {
  FinancialAccountDetailsServiceType,
  initFinancialAccountDetailsService,
} from '../pages/DetailsPage/api';
import {
  FinancialAccountListingServiceType,
  initFinancialAccountListingService,
} from '../pages/ListingPage/api';

export interface FinancialAccountServiceType {
  listing: FinancialAccountListingServiceType;
  store: FinancialAccountCreateServiceType;
  details: FinancialAccountDetailsServiceType;
}

export const initFinancialAccountService = (axios: AxiosInstance): FinancialAccountServiceType => ({
  listing: initFinancialAccountListingService(axios),
  store: initFinancialAccountCreateService(axios),
  details: initFinancialAccountDetailsService(axios),
});
