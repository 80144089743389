// revenueManagement: { rate: RateService },

import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { RootContext } from 'components/RootContainer';
import { MenuItem } from 'components/styled/SelectMenuItem';
import { FC, ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const RateField: FC<{
  value: string[] | null;
  onChange: (value: string[] | null) => void;
  error?: ReactNode;
}> = ({ value, onChange, error }) => {
  const {
    HotelApi: {
      revenueManagement: { rate: RateService },
    },
  } = useContext(RootContext);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['get-rates'],
    queryFn: () => {
      return RateService.rate.getAll({
        limit: 500,
      });
    },
  });

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === 'string' ? value.split(',') : value);
  };

  if (isLoading) {
    return <>...</>;
  }

  return (
    <FormControl variant="standard" sx={{ width: '100%' }} className="background">
      <InputLabel id="demo-multiple-name-label">Rate</InputLabel>
      <Select
        error={Boolean(error)}
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        multiple
        value={value ?? []}
        onChange={handleChange}
        // input={<BaseInput label="Name" />}
        MenuProps={MenuProps}
      >
        {(data || []).map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
            // style={getStyles(name, personName, theme)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
