import { InHouseReportRequestModel } from '../api/models/InHouseReportRequestModel';
import { InHouseReportFormModel } from '../models';

export const dataFormToRequest = (data: InHouseReportFormModel): InHouseReportRequestModel => ({
  from: data.from,
  to: data.to,
  card_ids: data.card_ids.map(card => card.id),
  rate_ids: data.rate_ids,
  room_type_ids: data.room_type_ids,
  day_price: data.day_price,
  nationality_ids: data.nationality_ids,
  all_pax: data.all_pax,
});
